import React, { useEffect, useState } from 'react';
import { makeStyles } from "tss-react/mui";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import {
    DatatableWrapper,
    Filter,
    Pagination,
    PaginationOptions,
    TableBody,
    TableHeader
} from 'react-bs-datatable';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Col, Row, Table } from 'react-bootstrap';
import DeleteConfirmationDialog from '../../components/DeleteConfirmationDialog';
import 'bootstrap/dist/css/bootstrap.min.css';
import EditSubcategory from '../../components/Edit/EditSubcategory';
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import CircularProgress from '@mui/material/CircularProgress';
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ListSubcategory = (props) => {
    const { loggedIn } = props;
    const [snackBar, setSnackBar] = useState({
        isOpen: false,
        status: '',
        message: '',
    });
    const [isLoading, setIsLoading] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [subcatId, setSubcatId] = useState(0);

    const [subcategories, setSubcategories] = useState([]);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    // Edit Category Modal starts here
    const [openEditSubcategoryModal, setOpenEditSubcategoryModal] = useState(false);
    const [editSubcategoryModalData, setEditSubcategoryModalData] = useState('');

    const handleOpenModal = (data) => {
        console.log(data);
        setOpenEditSubcategoryModal(true);
        setEditSubcategoryModalData(data);
    };
    const handleCloseModal = () => {
        setOpenEditSubcategoryModal(false);
    };
    // Edit Category Modal ends here


    //for delete button in the list
    const handleClickOpenDeleteDialog = (Subcategoryid) => {
        setOpenDeleteDialog(true);
        setSubcatId(Subcategoryid);
    };
    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
    };

    const handleConfirmDeleteDialog = () => {
        if (subcatId == 0) {
            setSnackBar({
                isOpen: true,
                status: 'error',
                message: "Can't find Subcategory ID",
            });
        } else {
            setOpenDeleteDialog(false);
            deleteSubcategory();
        }
    }
    //for delete button in the list

    const useStyles = makeStyles()(
        () => ({
            // mainContainer: {
            //     display: 'flex',
            //     flexDirection: 'column',
            //     flexWrap: 'wrap',
            // },
            // BtnContainer: {
            //     display: 'flex',
            //     justifyContent: 'space-evenly',
            //     flexWrap: 'nowrap',
            //     flexDirection: 'row',
            // },
            // Btn: {
            //     width: 150,
            // },
            // submitBtn: {
            //     color: '#FFF',
            // },
            // cancelBtn: {

            // }

        })
    );

    const { classes } = useStyles();

    const getSubcategories = async () => {
        setIsLoading(true);
        await fetch('/api/subcategories', {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${loggedIn.token}`
            },
        }
        )
            .then(response => response.json())
            .then(data => {
                setIsLoading(false);
                setSubcategories(data);
            });
    }

    const deleteSubcategory = async () => {
        setIsLoading(true);
        await fetch('/api/subcategory/delete', {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
                'Authorization': `Bearer ${loggedIn.token}`
            },
            body: JSON.stringify({
                SubcatID: subcatId
            })
        })
            .then(response => response.json())
            .then(data => {
                setIsLoading(false);
                if (data.status == 'success') {
                    console.log(subcatId)
                    setSubcategories(prev => prev.filter(item => item.SubcatID !== subcatId));
                }
                setSnackBar({
                    isOpen: true,
                    status: data.status,
                    message: data.message,
                });
            });
    }

    const handleEditSubcategoryModal = async (Subcategory) => {
        console.log(Subcategory)
        if (_.isEmpty(JSON.stringify(Subcategory.CatID))
            || _.isEmpty(Subcategory.Name_en)
            || _.isEmpty(JSON.stringify(Subcategory.Order))
        ) {
            setSnackBar({
                isOpen: true,
                status: 'error',
                message: 'Fill the required field',
            });
            return;
        }
        setIsLoading(true);
        await fetch('/api/subcategory/edit', {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
                'Authorization': `Bearer ${loggedIn.token}`
            },
            body: JSON.stringify({
                subcategory: Subcategory
            })
        })
            .then(response => response.json())
            .then(data => {
                setIsLoading(false);
                if (data.status == 'success') {
                    setOpenEditSubcategoryModal(false);
                    setSubcategories(data.subcategories);
                }
                setSnackBar({
                    isOpen: true,
                    status: data.status,
                    message: data.message,
                });
            });
    }

    // Create table headers consisting of 4 columns.
    const headers = [
        {
            title: 'Subcategory En', prop: 'Name_en', isFilterable: true,
            isSortable: true
        },
        {
            title: 'Subcategory Ar', prop: 'Name_ar', isFilterable: true,
            isSortable: true
        },
        {
            title: 'Category En', prop: 'Name', isFilterable: true, isSortable: true, cell: (row) => (row?.category?.Name_en)
        },
        {
            title: 'Category Ar', prop: 'Name', isFilterable: true, isSortable: true, cell: (row) => (row?.category?.Name_ar)
        },
        {
            title: 'Order By', prop: 'Order', isFilterable: true, isSortable: true
        },
        { title: 'User', prop: "userName", cell: (row) => (row.user.Username) },
        {
            title: 'Action',
            prop: "button",
            cell: (row) => (
                <>
                    <IconButton aria-label="edit" color="primary" onClick={() => handleOpenModal(row)}>
                        <EditIcon />
                    </IconButton>
                    <IconButton aria-label="delete" color="primary" onClick={() => handleClickOpenDeleteDialog(row.SubcatID)}>
                        <DeleteIcon />
                    </IconButton>
                </>

            )
        }
    ];

    useEffect(() => {
        getSubcategories();
    }, [])

    return (
        <>
            {isLoading && <Box sx={{ position: 'absolute', right: '20px', top: '80px' }}>
                <CircularProgress />
            </Box>}
            <Box
                component="form"

                noValidate
                autoComplete="off"
                style={{ padding: 15 }}
            >
                <Grid container spacing={{ xs: 1, md: 1 }} columns={{ xs: 1, sm: 1, md: 1 }}>
                    <Grid item xs={1} sm={2} md={2}>
                        <Paper variant="outlined" style={{ padding: 15 }}>
                            <Typography variant='h5'><FormatListBulletedIcon />List Subcategorys</Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={1} sm={2} md={2}>
                        <Paper variant="outlined" style={{ padding: 15 }}>
                            <DatatableWrapper
                                body={subcategories}
                                headers={headers}
                                paginationOptionsProps={{
                                    initialState: {
                                        rowsPerPage: 10,
                                        options: [5, 10, 15, 20],
                                        filteredDataLength: 10,
                                    },
                                }}>
                                <Row className="mb-4">
                                    <Col
                                        xs={12}
                                        lg={4}
                                        className="d-flex flex-col justify-content-end align-items-end"
                                    >
                                        <Filter placeholder="Search..." />
                                    </Col>
                                    <Col
                                        xs={12}
                                        sm={6}
                                        lg={4}
                                        className="d-flex flex-col justify-content-lg-center align-items-center justify-content-sm-start mb-2 mb-sm-0"
                                    >
                                        <PaginationOptions />
                                    </Col>
                                    <Col
                                        xs={12}
                                        sm={6}
                                        lg={4}
                                        className="d-flex flex-col justify-content-end align-items-end"
                                    >
                                        <Pagination labels={{
                                            firstPage: '<<',
                                            prevPage: '<',
                                            nextPage: '>',
                                            lastPage: '>>',
                                        }} />

                                    </Col>
                                </Row>
                                <Table responsive>
                                    <TableHeader />
                                    <TableBody />
                                </Table>

                            </DatatableWrapper>
                        </Paper>
                    </Grid>
                </Grid>
                <DeleteConfirmationDialog handleOpen={openDeleteDialog} handleConfirmClick={handleConfirmDeleteDialog} handleCloseClick={handleCloseDeleteDialog} />

                <EditSubcategory modalData={editSubcategoryModalData} handleOpenModal={openEditSubcategoryModal} handleEditSubcategoryModal={handleEditSubcategoryModal} handleCloseModal={handleCloseModal} />

                <Snackbar
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    open={snackBar.isOpen}
                    autoHideDuration={6000}
                    onClose={() => setSnackBar({ ...snackBar, isOpen: false })}
                    message={snackBar.message}
                >
                    <Alert severity={snackBar.status} sx={{ width: '100%' }}>
                        {snackBar.message}
                    </Alert>
                </Snackbar>
            </Box>
        </>
    );
};

const mapStateToProps = (state) => {
    return state
}

export default connect(mapStateToProps)(ListSubcategory);