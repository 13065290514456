import React, { useEffect, useState, useRef, forwardRef } from 'react';
import { makeStyles } from "tss-react/mui";
import Grid from '@mui/material/Grid';
import { Box, Switch } from '@mui/material';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { PhotoProvider, PhotoView } from 'react-photo-view';
import 'react-photo-view/dist/react-photo-view.css';
import _ from 'lodash';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
// import Barcode from 'react-barcode';
import EditItem from '../../components/Edit/EditProduct';

import DeleteConfirmationDialog from '../../components/DeleteConfirmationDialog';
import {
    DatatableWrapper,
    Filter,
    Pagination,
    PaginationOptions,
    TableBody,
    TableHeader,
    TableRow
} from 'react-bs-datatable';
import { useHistory, useLocation } from 'react-router-dom';
import { Col, Row, Table } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import TextFieldsOutlinedIcon from '@mui/icons-material/TextFieldsOutlined';
import EscalatorWarningOutlinedIcon from '@mui/icons-material/EscalatorWarningOutlined';
import HistoryIcon from '@mui/icons-material/History';
import PendingOutlinedIcon from '@mui/icons-material/PendingOutlined';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import { connect } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import MuiAlert from '@mui/material/Alert';
import ArticleIcon from '@mui/icons-material/Article';
import Snackbar from '@mui/material/Snackbar';
import Chip from '@mui/material/Chip';
import LazyLoad from 'react-lazy-load';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ListItem = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const location = useLocation();
    const history = useHistory();
    const [snackBar, setSnackBar] = useState({
        isOpen: false,
        status: '',
        message: '',
    });
    const { loggedIn } = props;
    const [products, setProducts] = useState([]); //get all item from server
    const [productId, setProductId] = useState(0);
    const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    // Edit Item Modal starts here
    const [openEditItemModal, setOpenEditItemModal] = useState(false);
    const [editItemModalData, setEditItemModalData] = useState('');
    const handleOpenEditItemModal = (row) => {
        history.push({
            pathname: '/product/edit',
            state: { row }
        });
    };

    // Edit Item Modal ends here

    //for delete button in the list
    const handleClickOpenDeleteDialog = (prodID) => {
        setOpenDeleteDialog(true);
        setProductId(prodID)
    };
    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
    };

    const handleConfirmDeleteDialog = () => {
        setOpenDeleteDialog(false);
        deleteProduct();
    }
    const deleteProduct = async () => {
        setIsLoading(true);
        await fetch('/api/product/delete', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${loggedIn.token}`
            },
            body: JSON.stringify({
                ProductID: productId
            })
        })
            .then(response => response.json())
            .then(data => {
                setIsLoading(false);
                if (data.status == 'success') {
                    setProducts(prev => prev.filter(item => item.ProductID !== productId));
                }
                setSnackBar({
                    isOpen: true,
                    status: data.status,
                    message: data.message,
                });
            });
    }
    //for delete button in the list


    const useStyles = makeStyles()(
        () => ({
            mainContainer: {
                display: 'flex',
                flexDirection: 'column',
                flexWrap: 'wrap',
            },
            BtnContainer: {
                display: 'flex',
                justifyContent: 'space-evenly',
                flexWrap: 'nowrap',
                flexDirection: 'row',
            },
            Btn: {
                width: 150,
            },
            submitBtn: {
                color: '#FFF',
            },
            cancelBtn: {

            }

        })
    );

    const { classes } = useStyles();

    const getProducts = async () => {
        setIsLoading(true);
        await fetch('/api/products', {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${loggedIn.token}`
            },
        })
            .then(response => response.json())
            .then(data => {
                setProducts(data);
                setIsLoading(false);
            });
    }


    // Create table headers consisting of 4 columns.
    const headers = [
        {
            title: 'Name En', prop: 'Name_en', isFilterable: true,
            isSortable: true
        },
        {
            title: 'Name Ar', prop: 'Name_ar', isFilterable: true,
            isSortable: true
        },
        {
            title: 'Price', prop: 'Price', isFilterable: true,
            isSortable: true,
        },
        {
            title: 'SubCat En', prop: 'SubcatID', isFilterable: true,
            isSortable: true, cell: (row) => (row.subcategory.Name_en)
        },
        {
            title: 'SubCat Ar', prop: 'SubcatID', isFilterable: true,
            isSortable: true, cell: (row) => (row.subcategory.Name_ar)
        },
        {
            title: 'Ingredients En', prop: 'ingredients_en', isFilterable: true,
            isSortable: true, cell: (row) => (
                row?.ingredients_en.map((ingredient, index) => (
                    <Chip label={ingredient?.Name} size="small" variant="outlined" key={index}/>
                ))
            )
        },
        {
            title: 'Ingredients Ar', prop: 'ingredients_ar', isFilterable: true,
            isSortable: true, cell: (row) => (
                row?.ingredients_ar.map((ingredient, index) => (
                    <Chip label={ingredient?.Name} size="small" variant="outlined" key={index}/>
                ))
            )
        },
        {
            title: 'Order', prop: 'Order', isFilterable: true, isSortable: true
        },
        {
            title: 'Image', prop: 'Image', cell: (row) => (
                <LazyLoad width={50} threshold={0.95}>
                <PhotoProvider>
                    <PhotoView src={row.Image}>
                        <img src={row.Image} width={50} />
                    </PhotoView>
                </PhotoProvider>
                </LazyLoad>
            )
        },
        {
            title: 'Actions',
            prop: "button",
            cell: (row) => (
                <>
                    <DropdownButton title={<PendingOutlinedIcon />} id="bg-nested-dropdown">

                        <Dropdown.Item onClick={() => handleOpenEditItemModal(row)}>
                            <ArticleIcon color="primary" fontSize="small" /> Edit
                        </Dropdown.Item>


                        <Dropdown.Item onClick={() => handleClickOpenDeleteDialog(row.ProductID)}>
                            <DeleteIcon color="primary" fontSize="small" /> Delete
                        </Dropdown.Item>
                    </DropdownButton>
                </>
            )
        }
    ];


    useEffect(() => {
        getProducts();
    }, [])


    return (
        <>
            {isLoading && <Box sx={{ position: 'absolute', right: '20px', top: '80px' }}>
                <CircularProgress />
            </Box>}
            <Box
                component="form"
                noValidate
                autoComplete="off"
                style={{ padding: 15 }}
            >
                <Grid container spacing={{ xs: 1, md: 1 }} columns={{ xs: 1, sm: 1, md: 1 }}>
                    <Grid item xs={1} sm={2} md={2}>
                        <Paper variant="outlined" style={{ padding: 15 }}>
                            <Typography variant='h5'><FormatListBulletedIcon />List Items</Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={1} sm={2} md={2}>
                        <Paper variant="outlined" style={{ padding: 15 }}>
                            <DatatableWrapper
                                body={products}
                                headers={headers}
                                sortProps={{
                                    initialState: {
                                        prop: 'PurchaseDate',
                                        order: 'desc'
                                    }
                                }}
                                paginationOptionsProps={{
                                    initialState: {
                                        rowsPerPage: 10,
                                        options: [5, 10, 15, 20],
                                        filteredDataLength: 10,
                                    },
                                }}>
                                <Row className="mb-4">
                                    <Col
                                        xs={12}
                                        lg={4}
                                        className="d-flex flex-col justify-content-end align-items-end"
                                    >
                                        <Filter placeholder="Search by Name"
                                        />
                                    </Col>
                                    <Col
                                        xs={12}
                                        sm={6}
                                        lg={4}
                                        className="d-flex flex-col justify-content-lg-center align-items-center justify-content-sm-start mb-2 mb-sm-0"
                                    >
                                        <PaginationOptions />
                                    </Col>
                                    <Col
                                        xs={12}
                                        sm={6}
                                        lg={4}
                                        className="d-flex flex-col justify-content-end align-items-end"
                                    >
                                        <Pagination labels={{
                                            firstPage: '<<',
                                            prevPage: '<',
                                            nextPage: '>',
                                            lastPage: '>>',
                                        }} />

                                    </Col>
                                </Row>
                                <Table responsive>
                                    <TableHeader />
                                    <TableBody />
                                </Table>
                            </DatatableWrapper>
                        </Paper>
                    </Grid>
                </Grid>

                <DeleteConfirmationDialog handleOpen={openDeleteDialog} handleConfirmClick={handleConfirmDeleteDialog} handleCloseClick={handleCloseDeleteDialog} />
                {/* {openEditItemModal && <EditItem modalData={editItemModalData} handleOpenModal={true} handleCloseModal={handleCloseEditItemModal} />} */}
            </Box>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={snackBar.isOpen}
                autoHideDuration={6000}
                onClose={() => setSnackBar({ ...snackBar, isOpen: false })}
                message={snackBar.message}
            >
                <Alert severity={snackBar.status} sx={{ width: '100%' }}>
                    {snackBar.message}
                </Alert>
            </Snackbar>
        </>
    );
};

const mapStateToProps = (state) => {
    return state
}

export default connect(mapStateToProps)(ListItem);