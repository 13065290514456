import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import { useProSidebar } from 'react-pro-sidebar';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';


const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


function ButtonAppBar(props) {
    const { collapseSidebar } = useProSidebar();
    const history = useHistory();
    const [snackBar, setSnackBar] = useState({
        isOpen: false,
        status: '',
        message: '',
    });
    const { loggedIn, companyData } = props;
    const login = () => {
        history.push('/Login');
    }
    const logout = async () => {
        await fetch('/api/logout', {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${loggedIn.token}`
            }
        })
            .then(response => response.json())
            .then(data => {
                if (data.status == 'success') {
                    
                    props.logoutUser(loggedIn);
                    history.push('/');
                    
                }
                setSnackBar({
                    isOpen: true,
                    status: data.status,
                    message: data.message,
                });
            });

    }
    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static">
                <Toolbar>
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{ mr: 2 }}
                        onClick={() => {
                            collapseSidebar()
                        }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        <span onClick={() => { history.push('/') }} style={{ cursor: 'pointer' }}>{companyData.company}</span>
                    </Typography>
                    {!loggedIn.status ?
                        <Button color="inherit" onClick={login}>Login</Button> :
                        <Typography variant='p'>
                            <Link to='/settings' style={{ margin: '0 10px', color: '#FFF', textDecoration: 'none', fontWeight: '400' }}><span>{loggedIn.user.Username}</span></Link>|<Button color="inherit" onClick={logout}><LogoutIcon /></Button>
                        </Typography>
                    }
                </Toolbar>
            </AppBar>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={snackBar.isOpen}
                autoHideDuration={6000}
                onClose={() => setSnackBar({ ...snackBar, isOpen: false })}
                message={snackBar.message}
            >
                <Alert severity={snackBar.status} sx={{ width: '100%' }}>
                    {snackBar.message}
                </Alert>
            </Snackbar>
        </Box>
    );
}

const mapStateToProps = (state) => {
    return state
}
const dispatchCompany = (dispatch) => {
    return {
        logoutUser: (loggedIn) => { dispatch({ type: 'LOGOUT_USER', token: loggedIn.token }) }
    }
}
export default connect(mapStateToProps, dispatchCompany)(ButtonAppBar);