import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from "tss-react/mui";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ImageUploader from '../../components/ImageUploader';
import { connect } from 'react-redux';
import { MuiChipsInput } from 'mui-chips-input';
import CircularProgress from '@mui/material/CircularProgress';
import {
    Grid,
    Box,
    TextField,
    Divider,
    Button,
    Paper,
    Stack,
    Switch,
    Autocomplete,
    Typography,
    IconButton
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const AddItem = (props) => {
    const history = useHistory();

    const { loggedIn } = props;

    const [snackBar, setSnackBar] = useState({
        isOpen: false,
        status: '',
        message: '',
    });
    const [categories, setCategories] = useState([]); //categories from db as list
    const [subcategories, setSubcategories] = useState([]); //categories from db as list


    const [newItem, setNewItem] = useState({
        CatID: '',
        SubcatID: '',
        Name_en: '',
        Name_ar: '',
        Order: 0,
        Price: 0,
        Ingredients_en: [],
        Ingredients_ar: [],
        Image: ''
    });

    const useStyles = makeStyles()(
        () => ({
            mainContainer: {
                display: 'flex',
                flexDirection: 'column',
                flexWrap: 'wrap',
            },
            BtnContainer: {
                display: 'flex',
                justifyContent: 'space-evenly',
                flexWrap: 'nowrap',
                flexDirection: 'row',
            },
            Btn: {
                minWidth: 150,
            },
            submitBtn: {
                color: '#FFF',
            },
            DatePicker: {
                '& input.MuiInputBase-input': {
                    height: '8px'
                }
            }

        })
    );
    const { classes } = useStyles();


    const handleChangeIngredients_en = (newChips) => {
        setNewItem({
            ...newItem,
            Ingredients_en: newChips
        })
    }
    const handleChangeIngredients_ar = (newChips) => {
        setNewItem({
            ...newItem,
            Ingredients_ar: newChips
        })
    }

    const getCategories = async () => {
        await fetch('/api/categories', {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${loggedIn.token}`
            },
        })
            .then(response => response.json())
            .then(data => {
                setCategories(data);
            });
    }
    const getSubcategories = async (CatID) => {
        await fetch('/api/subcategories_by_category?CatID=' + CatID, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${loggedIn.token}`
            },
        })
            .then(response => response.json())
            .then(data => {
                if (data.status == 'success') {
                    setSubcategories(data.subcategories);
                }
            });
    }

    const handleImageChange = (image) => {
        setNewItem({
            ...newItem,
            Image: image
        });
    };


    const submitNewItem = async () => {
        await fetch('/api/product', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${loggedIn.token}`
            },
            body: JSON.stringify({
                item: newItem
            })
        })
            .then(response => response.json())
            .then(data => {
                if (data.status == 'success') {
                    setNewItem({
                        CatID: '',
                        SubcatID: '',
                        Name_en: '',
                        Name_ar: '',
                        Price: 0,
                        Ingredients: [],
                        Image: ''
                    })
                }
                setSnackBar({
                    isOpen: true,
                    status: data.status,
                    message: data.message,
                });
            });
    }

    const clearForm = () => {
        setNewItem({
            CatID: '',
            SubcatID: '',
            Name_en: '',
            Name_ar: '',
            Price: 0,
            Ingredients: [],
            Image: ''
        })
    }

    useEffect(() => {
        getCategories();
    }, []);
    return (
        <>
            <Box
                component="form"
                noValidate
                autoComplete="off"
                style={{ padding: 15 }}
            >
                <Grid container spacing={{ xs: 1, md: 1 }} columns={{ xs: 1, sm: 1, md: 4 }}>
                    <Grid item xs={1} sm={3} md={4}>
                        <Paper variant="outlined" style={{ padding: 15 }}>
                            <Grid container spacing={{ xs: 1, md: 4 }} columns={{ xs: 1, sm: 1, md: 12 }}>
                                <Grid item xs={1} sm={2} md={4}>
                                    <Typography variant='h5'><AddCircleOutlineIcon /> Add Item</Typography>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item xs={1} sm={2} md={4}>
                        <Paper variant="outlined" style={{ padding: 15 }}>
                            <Grid container spacing={{ xs: 1, md: 1 }} columns={{ xs: 1, sm: 1, md: 6 }} className={classes.mainContainer}>
                                <Grid item xs={1} sm={1} md={6}>
                                    <Grid container spacing={{ xs: 1, md: 1 }} columns={{ xs: 1, sm: 1, md: 6 }}>
                                        <Grid item xs={1} sm={1} md={4.5}>
                                            <Grid container spacing={{ xs: 1, md: 2 }} columns={{ xs: 1, sm: 1, md: 3 }}>
                                                <Grid item xs={1} sm={1} md={3}>
                                                    <Grid container spacing={{ xs: 1, md: 1 }} columns={{ xs: 1, sm: 1, md: 3 }}>
                                                        <Grid item xs={1} sm={1} md={1}>
                                                            {categories ? (
                                                                <Autocomplete
                                                                    disablePortal
                                                                    size='small'
                                                                    id="category"
                                                                    loading={true}
                                                                    options={categories}
                                                                    value={newItem.CatID ? categories.find(category => category.CatID === newItem.CatID) : null}
                                                                    onChange={(event, value) => {
                                                                        setNewItem({ ...newItem, CatID: value.CatID });
                                                                        getSubcategories(value.CatID)
                                                                    }}
                                                                    getOptionLabel={option => option.Name_en}
                                                                    renderInput={(params) => <TextField {...params} label="Select Category" />}
                                                                />
                                                            ) : (
                                                                <CircularProgress size='1.5rem' />
                                                            )}
                                                        </Grid>
                                                        <Grid item xs={1} sm={1} md={1}>
                                                            <Autocomplete
                                                                disablePortal
                                                                size='small'
                                                                id="subcategory"
                                                                loading={true}
                                                                options={subcategories}
                                                                value={newItem.SubcatID ? subcategories.find(subcategory => subcategory.SubcatID === newItem.SubcatID) : null}
                                                                onChange={(event, value) => {
                                                                    setNewItem({
                                                                        ...newItem,
                                                                        SubcatID: value.SubcatID
                                                                    })

                                                                }}
                                                                getOptionLabel={option => option.Name_en}
                                                                renderInput={(params) => <TextField {...params} label="Select Subcategory" />}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={1} sm={1} md={1}>
                                                            <TextField
                                                                required
                                                                fullWidth
                                                                type='number'
                                                                id="outlined-required"
                                                                label="Product Order"
                                                                size='small'
                                                                value={newItem.Order}
                                                                onChange={(event) => {
                                                                    setNewItem({
                                                                        ...newItem,
                                                                        Order: event.target.value
                                                                    })
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={1} sm={1} md={3}>
                                                    <Grid container spacing={{ xs: 1, md: 1 }} columns={{ xs: 1, sm: 1, md: 3 }}>
                                                        <Grid item xs={1} sm={1} md={1}>
                                                            <TextField
                                                                required
                                                                fullWidth
                                                                id="outlined-required"
                                                                label="Item Name En"
                                                                size='small'
                                                                value={newItem.Name_en}
                                                                onChange={(event) => {
                                                                    setNewItem({
                                                                        ...newItem,
                                                                        Name_en: event.target.value
                                                                    })
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={1} sm={1} md={1}>
                                                            <TextField
                                                                required
                                                                fullWidth
                                                                id="outlined-required"
                                                                label="Item Name Ar"
                                                                size='small'
                                                                value={newItem.Name_ar}
                                                                onChange={(event) => {
                                                                    setNewItem({
                                                                        ...newItem,
                                                                        Name_ar: event.target.value
                                                                    })
                                                                }}
                                                                sx={{ direction: 'rtl' }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={1} sm={1} md={1}>
                                                            <TextField
                                                                required
                                                                fullWidth
                                                                id="outlined-required"
                                                                label="Price"
                                                                size='small'
                                                                type='number'
                                                                value={newItem.Price}
                                                                onChange={(event) => {
                                                                    setNewItem({
                                                                        ...newItem,
                                                                        Price: event.target.value
                                                                    })
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={1} sm={1} md={3}>
                                                    <Grid container spacing={{ xs: 1, md: 1 }} columns={{ xs: 1, sm: 1, md: 1 }}>
                                                        <Grid item xs={1} sm={1} md={1}>
                                                            <MuiChipsInput
                                                                fullWidth
                                                                label="Ingredients En"
                                                                size="small"
                                                                variant="outlined"
                                                                value={newItem.Ingredients_en}
                                                                validate={(chipValue) => chipValue.length >= 3}
                                                                onChange={handleChangeIngredients_en} />
                                                        </Grid>
                                                        <Grid item xs={1} sm={1} md={1}>
                                                            <MuiChipsInput
                                                                fullWidth
                                                                label="Ingredients Ar"
                                                                placeholder='اكتب واضغط على إدخال'
                                                                size="small"
                                                                variant="outlined"
                                                                value={newItem.Ingredients_ar}
                                                                validate={(chipValue) => chipValue.length >= 3}
                                                                onChange={handleChangeIngredients_ar}
                                                                xs={{ textAlign: 'right' }} />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                            </Grid>
                                        </Grid>
                                        <Grid item xs={1} sm={1} md={1.5}>
                                            <Grid container spacing={{ xs: 1, md: 1 }} columns={{ xs: 1, sm: 1, md: 1 }}>
                                                <Grid item xs={1} sm={1} md={1}>
                                                    <ImageUploader image={newItem.image} onChange={handleImageChange} />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <hr />




                                <Grid item xs={1} sm={1} md={6} className={classes.BtnContainer}>
                                    <Grid container spacing={{ xs: 1, md: 1 }} columns={{ xs: 1, sm: 1, md: 2 }}>
                                        <Grid item xs={1} sm={1} md={1} style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                                            <Button variant="contained" onClick={submitNewItem} className={[classes.Btn, classes.submitBtn].join(' ')}>Add Item</Button>
                                        </Grid>
                                        <Grid item xs={1} sm={1} md={1} style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                                            <Button variant="outlined" onClick={clearForm} className={[classes.Btn, classes.cancelBtn].join(' ')}>Clear</Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid >


            </Box >
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={snackBar.isOpen}
                autoHideDuration={6000}
                onClose={() => setSnackBar({ ...snackBar, isOpen: false })}
                message={snackBar.message}
            >
                <Alert severity={snackBar.status} sx={{ width: '100%' }}>
                    {snackBar.message}
                </Alert>
            </Snackbar>
        </>
    );
};





const mapStateToProps = (state) => {
    return state
}
const dispatchCompany = (dispatch) => {
    return {
        autoSaveItem: (savedItem) => { dispatch({ type: 'AUTO_SAVE_NEW_ITEM', savedItem: JSON.stringify(savedItem) }) }
    }
}
export default connect(mapStateToProps, dispatchCompany)(AddItem);