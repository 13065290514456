import React, { useEffect, useState } from 'react';
import { makeStyles } from "tss-react/mui";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import { connect } from 'react-redux';
import Typography from '@mui/material/Typography';
import Snackbar from '@mui/material/Snackbar';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import MuiAlert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';

import * as Icons from '@mui/icons-material';
import _ from 'lodash';
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const AddCategory = (props) => {
    const { loggedIn } = props;
    const [isLoading, setIsLoading] = useState(false);
    const [snackBar, setSnackBar] = useState({
        isOpen: false,
        status: '',
        message: '',
    });
    const [category, setCategory] = useState({
        category_en: '',
        category_ar: '',
        icon: ''
    })
    const [selectedIcon, setSelectedIcon] = useState(null);
    const iconNames = [
        'EmojiFoodBeverage',
        'Fastfood',
        'FoodBank',
        'Coffee',
        'Icecream',
        'KebabDining',
        'Cake',
        'LocalDining',
        'Restaurant',
        'RestaurantMenu',
        'LocalBar',
        'LocalCafe',
        'Nightlife',
        'LocalPizza',
        'BakeryDining',
        'LunchDining',
        'SmokingRooms'
    ];
    const [error, setError] = useState('');
    const useStyles = makeStyles()(
        () => ({
            mainContainer: {
                display: 'flex',
                flexDirection: 'column',
                flexWrap: 'wrap',
            },
            BtnContainer: {
                display: 'flex',
                justifyContent: 'space-evenly',
                flexWrap: 'nowrap',
                flexDirection: 'row',
            },
            Btn: {
                width: 150,
            },
            submitBtn: {
                color: '#FFF',
            },
            cancelBtn: {

            }

        })
    );
    const { classes } = useStyles();
    const handleIconSelection = (iconName) => {
        setCategory({
            ...category,
            icon: iconName
        });
    };
    const iconList = Object.keys(Icons);
    const submit = async () => {
        if (_.isEmpty(category)) {
            setSnackBar({
                isOpen: true,
                status: 'error',
                message: 'Fill the required field(s)',
            });
            return;
        }
        setIsLoading(true);
        await fetch('/api/category', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${loggedIn.token}`
            },
            body: JSON.stringify(category)
        })
            .then(response => response.json())
            .then(data => {
                setIsLoading(false);
                if (data.status == 'success') {
                    setCategory({
                        category_en: '',
                        category_ar: ''
                    });
                }
                setSnackBar({
                    isOpen: true,
                    status: data.status,
                    message: data.message,
                });
            })
    }

    return (
        <>
            {isLoading && <Box sx={{ position: 'absolute', right: '20px', top: '80px' }}>
                <CircularProgress />
            </Box>}
            <Box
                component="form"
                noValidate
                autoComplete="off"
                style={{ padding: 15 }}
            >
                <Grid container spacing={{ xs: 1, md: 1 }} columns={{ xs: 1, sm: 1, md: 1 }}>
                    <Grid item xs={1} sm={2} md={2}>
                        <Paper variant="outlined" style={{ padding: 15 }}>
                            <Typography variant='h5'><AddCircleOutlineIcon /> Add Category</Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={1} sm={2} md={2}>
                        <Paper variant="outlined" style={{ padding: 15 }}>
                            <Grid container spacing={{ xs: 1, md: 1 }} columns={{ xs: 1, sm: 1, md: 1 }} className={classes.mainContainer}>
                                <Grid item xs={1} sm={1} md={1} style={{ display: 'flex' }}>
                                    <TextField
                                        fullWidth
                                        required
                                        id="outlined-required"
                                        label="Category Name En"
                                        value={category.Name}
                                        onChange={event => setCategory({
                                            ...category,
                                            category_en: event.target.value
                                        })}
                                    />
                                </Grid>
                                <Grid item xs={1} sm={1} md={1} style={{ display: 'flex' }}>
                                    <TextField
                                        fullWidth
                                        required
                                        id="outlined-required"
                                        label="Category Name Ar"
                                        value={category.Name}
                                        onChange={event => setCategory({
                                            ...category,
                                            category_ar: event.target.value
                                        })}
                                        sx={{ direction: 'rtl' }}
                                    />
                                </Grid>
                                <Grid item xs={1} sm={1} md={1} style={{ display: 'flex', flexWrap: 'wrap' }}>
                                    {iconNames.map((iconName, index) => {
                                        const IconComponent = Icons[iconName];
                                        return (
                                            <Button
                                                key={index}
                                                onClick={() => handleIconSelection(iconName)}
                                                variant={category.icon === iconName ? 'contained' : 'outlined'}
                                            >
                                                <IconComponent />
                                            </Button>
                                        );
                                    })}
                                </Grid>
                                <Grid item xs={1} sm={1} md={2} className={classes.BtnContainer}>
                                    <Button variant="contained" className={[classes.Btn, classes.submitBtn].join(' ')} onClick={submit}>Add</Button>
                                    {/* <Button variant="outlined" className={[classes.Btn, classes.cancelBtn].join(' ')}>Clear</Button> */}
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
                <Snackbar
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    open={snackBar.isOpen}
                    autoHideDuration={6000}
                    onClose={() => setSnackBar({ ...snackBar, isOpen: false })}
                    message={snackBar.message}
                >
                    <Alert severity={snackBar.status} sx={{ width: '100%' }}>
                        {snackBar.message}
                    </Alert>
                </Snackbar>
            </Box>

        </>
    );
};


const mapStateToProps = (state) => {
    return state
}

export default connect(mapStateToProps)(AddCategory);