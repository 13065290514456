import { CssBaseline, ThemeProvider, createTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Router from './router';
import { connect } from 'react-redux';
import './App.css';

function App(props) {
  const theme = createTheme({
    palette: {
      primary: {
        // main: '#ff7400', //'#F79325',
        main: props.companyData.primaryColor || '#FFF',
        contrastText: '#fff',
      },
    },
    direction: 'ltr',
    typography: {
      fontFamily: ['Classic Sans Rounded', 'sans-serif'].join(','),

    },
    shape: {
      borderRadius: 20,
    },
  });
  const getDefaultSettings = async () => {
    console.log(props);
    if (!props.loggedIn.status) {
      await fetch('/api/default_settings')
        .then(response => response.json())
        .then(data => {
          props.setCompanyData(data.Company, data.Logo, data.PrimaryColor);
          document.documentElement.style.setProperty('--primary-color-stock', data.PrimaryColor);
        });
    }

  }

  // const getSettings = async () => {
  //   await fetch('/api/settings')
  //     .then(response => response.json())
  //     .then(data => {
  //       console.log(data);
  //       props.setCompanyData(data.Company, data.Logo, data.PrimaryColor);
  //       document.documentElement.style.setProperty('--primary-color-stock', data.PrimaryColor);
  //     });
  // }
  useEffect(() => {
    // if (props.companyData.primaryColor == '' || props.companyData.primaryColor == undefined) {
    getDefaultSettings();
    // }else{
    //   document.documentElement.style.setProperty('--primary-color-stock', props.companyData.primaryColor);
    // }
  }, [])
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router />
    </ThemeProvider>
  );
}
const mapStateToProps = (state) => {
  return state
}
const dispatchCompany = (dispatch) => {
  return {
    setCompanyData: (company, logo, primaryColor) => { dispatch({ type: 'SET_COMPANY_DATA', company: company, logo: logo, primaryColor: primaryColor }) },
  }
}
export default connect(mapStateToProps, dispatchCompany)(App);
