import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from "tss-react/mui";
import { Grid, Button, Box, Paper } from '@mui/material';
import { connect } from 'react-redux';
import { QRCodeSVG } from 'qrcode.react';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import ReactToPrint from 'react-to-print';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedinIcon from '@mui/icons-material/LinkedIn';

const Dashboard = (props) => {
    const {loggedIn} = props;
    const history = useHistory();
    const [settings, setSettings] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const QRCodeRef = useRef();
    const useStyles = makeStyles()(
        () => ({
            root: {
                alignContent: 'center',
                justifyContent: 'center',
                minHeight: '80vh'
            },
            Logo: {
                maxHeight: '400px'
            }
        })
    );
    const { classes } = useStyles();

    const { companyData } = props;

    const getSettings = async () => {
        setIsLoading(true);
        await fetch('/api/settings', {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${loggedIn.token}`
            }
        })
            .then(response => response.json())
            .then(data => {
                setIsLoading(false);
                setSettings(data);
            });
    }
    const ViewMenu = () => {
        const externalURL = `${window.location.origin}/menu/${settings.URL}`;
        window.open(externalURL, '_blank');
    }
    useEffect(() => { getSettings() }, [])
    return (
        <>
            {isLoading && <Box sx={{ position: 'absolute', right: '20px', top: '80px' }}>
                <CircularProgress />
            </Box>}
            <Grid container spacing={3} columns={{ xs: 1, sm: 1, md: 1 }} style={{
                width: '400px',
                margin: '50px auto 0 auto',
                textAlign: 'center'
            }}>
                <Grid item>
                    <ReactToPrint
                        trigger={() => <Button variant="contained">Print QR Code</Button>}
                        content={() => QRCodeRef.current}
                    />
                </Grid>
                <Grid item>
                    <Button variant="contained" onClick={() => ViewMenu()}>View Menu</Button>
                </Grid>
            </Grid>

            <Grid container spacing={3} columns={{ xs: 1, sm: 1, md: 1 }} style={{
                width: '400px',
                margin: '50px auto 0 auto',
                border: `2px solid ${props.companyData.primaryColor}`,
                borderRadius: '15px',
                textAlign: 'center'
            }} ref={QRCodeRef}>
                <Grid item xs={1} sm={1} md={1} style={{ paddingLeft: 0 }}>
                    <Typography variant='h6' style={{ fontFamily: 'arial' }}>{settings.Name}</Typography>
                </Grid>
                <Grid item xs={1} sm={1} md={1} style={{ paddingLeft: 0 }}>
                    <img src={settings.Logo} width={150} />
                </Grid>
                <Grid item xs={1} sm={1} md={1} style={{ paddingLeft: 0 }}>
                    <Typography style={{ fontFamily: 'arial' }}>SCAN FOR</Typography>
                </Grid>
                <Grid item xs={1} sm={1} md={1} style={{
                    paddingTop: 0,
                    paddingLeft: 0

                }}>
                    <Typography style={{ fontFamily: 'lora', fontSize: '48px', lineHeight: 1 }}>MENU</Typography>
                </Grid>
                <Grid item xs={1} sm={1} md={1} style={{ paddingLeft: 0 }}>
                    <QRCodeSVG
                        value={`${window.location.origin}/menu/${settings.URL}`}
                        size={256}
                        bgColor={"#ffffff"}
                        fgColor={"#000000"}
                        level={"H"}
                        includeMargin={false}
                        imageSettings={{
                            src: settings.Logo,
                            x: undefined,
                            y: undefined,
                            height: 30,
                            width: 30,
                            excavate: true,
                        }}
                    />
                </Grid>
                <Grid item xs={1} sm={1} md={1} style={{ paddingLeft: 0 }}>
                    <Typography style={{
                        background: settings.PrimaryColor,
                        color: 'white'
                    }}>SCAN & ORDER</Typography>
                </Grid>
                <Grid item xs={1} sm={1} md={1} style={{ paddingLeft: 0 }}>
                    <Grid container style={{
                        padding: '0px 10px 3px 10px',
                        display: 'flex',
                        flexWrap: 'nowrap',
                        justifyContent: 'space-between'
                    }}>
                        <Grid item>
                            <FacebookIcon color='primary' />
                            <InstagramIcon color='primary' />
                            <LinkedinIcon color='primary' />
                        </Grid>
                        <Grid item>
                            <Typography variant='body2'>Powered By: AramsTech</Typography>
                        </Grid>
                    </Grid>

                </Grid>

            </Grid>





        </>
    );
};

const mapStateToProps = (state) => {
    return state
}

export default connect(mapStateToProps)(Dashboard);