import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import LoadingContainer from '../containers/LoadingContainer';
import PrivateRoute from './PrivateRoute';
import Home from '../pages/Home';
import Dashboard from '../pages/Dashboard';
import AddCategory from '../pages/Categories/Add';
import ListCategory from '../pages/Categories/List';
import AddSubcategory from '../pages/Subcategories/Add';
import ListSubcategory from '../pages/Subcategories/List';
import AddProduct from '../pages/Products/Add';
import ListProduct from '../pages/Products/List';
import EditProduct from '../components/Edit/EditProduct';
import RestoMenu from '../pages/Resto/Menu';

import Login from '../pages/Auth/Login';
import Settings from '../pages/Settings';


const RouterController = () => {
    const { status } = 'loading';

    return (
        <LoadingContainer status={status}>
            <BrowserRouter>
                <Switch>
                    <Route path="/menu/:resto">
                        <RestoMenu />
                    </Route>
                    <Route exact path="/">
                        <Login />
                    </Route>
                    <PrivateRoute path="/dashboard">
                        <Dashboard />
                    </PrivateRoute>
                    <PrivateRoute path="/categories/add">
                        <AddCategory />
                    </PrivateRoute>
                    <PrivateRoute path="/categories/list">
                        <ListCategory />
                    </PrivateRoute>
                    <PrivateRoute path="/subcategories/add">
                        <AddSubcategory />
                    </PrivateRoute>
                    <PrivateRoute path="/subcategories/list">
                        <ListSubcategory />
                    </PrivateRoute>

                    <PrivateRoute path="/products/list">
                        <ListProduct />
                    </PrivateRoute>
                    <PrivateRoute path="/product/add">
                        <AddProduct />
                    </PrivateRoute>
                    <PrivateRoute path="/product/edit">
                        <EditProduct />
                    </PrivateRoute>
                    <PrivateRoute path="/settings">
                        <Settings />
                    </PrivateRoute>
                    {/* <Route exact path="/contact" component={Contact} /> */}

                    <Route path="/login">
                        <Login />
                    </Route>
                    <Route path="*">
                        <Login />
                    </Route>


                </Switch>
            </BrowserRouter>
        </LoadingContainer>
    );
};

export default RouterController;