import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { makeStyles } from "tss-react/mui";
import Link from "@mui/material/Link";
import Chip from '@mui/material/Chip';
import Container from "@mui/material/Container";
import CircularProgress from '@mui/material/CircularProgress';
import * as Icons from '@mui/icons-material';
import _, { map } from 'lodash';
import PropTypes from 'prop-types';
// import SwipeableViews from 'react-swipeable-views';
import SwipeableViews from 'react-swipeable-views-react-18-fix';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Paper } from '@mui/material';
import { PhotoProvider, PhotoView } from 'react-photo-view';
import { connect } from 'react-redux';
import Switch from '@mui/material/Switch';
import LazyLoad from 'react-lazy-load';

function TabPanel(props) {
    const { children, value, index, lang, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <div>{children}</div>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};
function TabPanelContent(props) {
    const { children, product, value, index, lang, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Grid container columns={{ xs: 1, sm: 1, md: 12 }} style={{ display: 'flex', flexWrap: 'nowrap', justifyContent: 'space-between', direction: lang === 'en' ? 'ltr' : 'rtl' }}>
                        <Grid item xs={0.5} sm={1} md={1}>
                            {product.Image &&
                                <LazyLoad width={50} threshold={0.95}>
                                    <PhotoProvider>
                                        <PhotoView src={product.Image}>

                                            <img src={product.Image} width={50} style={{ borderRadius: 50 }} />

                                        </PhotoView>
                                    </PhotoProvider>
                                </LazyLoad>
                            }
                        </Grid>
                        <Grid item xs={1} sm={1} md={8}>
                            <Grid container columns={{ xs: 1, sm: 1, md: 12 }}>
                                <Grid item xs={1} sm={1} md={8}>
                                    {lang === 'en' ?
                                        <Typography style={{ textAlign: 'left' }}>
                                            {product.Name_en}
                                        </Typography>
                                        : <Typography style={{ textAlign: 'right', fontFamily: 'adobe arabic', fontSize: '20px' }}>
                                            {product.Name_ar}
                                        </Typography>
                                    }

                                </Grid>
                                <Grid item xs={1} sm={1} md={8}>
                                    {lang === 'en' ?
                                        product.ingredients_en.map((chip, index) => (
                                            <Chip label={chip.Name} size="small" variant="outlined" key={index} />
                                        )) : product.ingredients_ar.map((chip, index) => (
                                            <Chip label={chip.Name} size="small" variant="outlined" key={index} />
                                        ))
                                    }

                                </Grid>
                            </Grid>

                        </Grid>
                        <Grid item xs={0.25} sm={1} md={1}>
                            {product.Price > 0 ? <Typography style={{ textAlign: 'right' }}>${product.Price}</Typography> : '-'}

                        </Grid>
                    </Grid>
                </Box>
            )
            }
        </div >
    );
}

TabPanelContent.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

const Menu = (props) => {
    const { companyData, loggedIn } = props;
    const history = useHistory();
    const [menu, setMenu] = useState([]);
    const [settings, setSettings] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [resto, setResto] = useState('');
    const [lang, setLang] = useState('en');
    //getting resto name from url
    const params = useParams();
    //getting resto name from url


    const useStyles = makeStyles()(
        () => ({
            root: {
                alignContent: 'center',
                justifyContent: 'center',
                minHeight: '80vh'
            },
            Logo: {
                maxHeight: '400px'
            },
            stickedMenu: {
                position: 'sticky',
                top: 0,
                backgroundColor: '#FFF',
                zIndex: 1
            },
        })
    );
    const { classes } = useStyles();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [value, setValue] = React.useState(0);
    const [valueVertical, setValueVertical] = React.useState(0);

    const handleChangeVertical = (event, _newValue) => {
        setValueVertical(_newValue);
    };
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeIndex = (index) => {
        setValue(index);
    };
    const handleChangeVerticalIndex = (index) => {
        setValueVertical(index);
    };

    const getMenu = async (resto) => {
        setIsLoading(true);
        await fetch('/api/get_menu?resto=' + resto)
            .then(response => response.json())
            .then(data => {
                setIsLoading(false);
                setMenu(data.menu);
                setSettings(data.settings);
                setLang(data.settings.PrimaryLanguage);
                setCheckedLanguage(data.settings.PrimaryLanguage == 'en' ? false : true);
                props.setCompanyData(data?.settings?.Name, data?.settings?.Logo, data?.settings?.PrimaryColor);
                document.documentElement.style.setProperty('--primary-color-stock', data?.settings?.PrimaryColor);
            });
    }
    const [checkedLanguage, setCheckedLanguage] = React.useState(false);

    const handleChangeLanguage = (event) => {
        setCheckedLanguage(event.target.checked);
        setLang(event.target.checked ? 'ar' : 'en');
    };
    useEffect(() => {
        if (!_.isEmpty(params['resto'])) {
            getMenu(params['resto']);
        }
    }, []);

    return (
        <>
            {isLoading && <Box sx={{ position: 'absolute', right: '20px', top: '80px' }}>
                <CircularProgress />
            </Box>}

            <Box sx={{ p: 3 }} style={{
                backgroundImage: `url(${settings.Banner})`,
                backgroundSize: 'cover'
            }}>
                <Paper variant="outlined" style={{
                    backgroundColor: '#ffffff6e'
                }}>
                    <Box sx={{ p: 3 }}>
                        {/* style={{ flexWrap: 'nowrap' }} */}
                        <Grid container spacing={{ xs: 1, md: 1 }} columns={{ xs: 1, sm: 1, md: 4 }}>
                            <Grid item xs={1} sm={1} md={1} sx={{ display: isMobile ? 'flex' : 'block' }}>
                                <img src={settings.Logo} width={200} style={{ margin: isMobile ? 'auto' : '0' }} />
                            </Grid>
                            <Grid item xs={1} sm={1} md={3} sx={{ textAlign: isMobile ? 'center' : 'left' }}>
                                <Typography variant='h4'>{settings.Name}</Typography>
                                <Typography variant='body1'>{settings.Slogon}</Typography>
                                <Typography variant='body1'>{settings.Phone}</Typography>
                                <Typography variant='body1'>{settings.Address}</Typography>
                            </Grid>
                            <Grid item xs={1} sm={1} md={1} sx={{ textAlign: 'center' }}>
                                <Grid container spacing={{ xs: 1, md: 1 }} columns={{ xs: 3, sm: 3, md: 3 }}>
                                    <Grid item xs={1} sm={1} md={1}>
                                        <Typography variant='body1' xs={{ textAlign: 'right' }}>English</Typography>
                                    </Grid>
                                    <Grid item xs={1} sm={1} md={1}>
                                        <Switch
                                            checked={checkedLanguage}
                                            onChange={handleChangeLanguage}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />
                                    </Grid>
                                    <Grid item xs={1} sm={1} md={1}>
                                        <Typography variant='body1' xs={{ textAlign: 'left' }}>عربي</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </Paper>
            </Box >
            <Box sx={{ bgcolor: 'background.paper' }}>
                <AppBar position="static">
                    <Tabs
                        style={{ direction: lang === 'en' ? 'ltr' : 'rtl' }}
                        value={value}
                        onChange={handleChange}
                        indicatorColor="secondary"
                        textColor="inherit"
                        variant="fullWidth"
                        aria-label="full width tabs example"
                    >
                        {menu.map((category, index) => {
                            const IconComponent = category.Icon ? Icons[category.Icon] : null;
                            return (
                                category.Icon ? <Tab icon={<IconComponent />} label={lang == 'en' ? category.Name_en : <Typography style={{ fontFamily: 'Adobe Arabic', fontSize: '20px' }}>{category.Name_ar}</Typography>} {...a11yProps(category.CatID)} key={index} /> :
                                    <Tab label={lang == 'en' ? category.Name_en : <Typography style={{ fontFamily: 'Adobe Arabic', fontSize: '20px' }}>{category.Name_ar}</Typography>} {...a11yProps(category.CatID)} key={index} />
                            );

                        })}
                    </Tabs>
                </AppBar>
                {/* <SwipeableViews
                    // axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                    index={value}
                    onChangeIndex={handleChangeIndex}
                > */}
                {menu.map((category, index) => (
                    <TabPanel value={value} index={index} key={index} lang={lang}>
                        <Tabs
                            value={valueVertical}
                            onChange={handleChangeVertical}
                            variant="scrollable"
                            scrollButtons
                            allowScrollButtonsMobile
                            className={classes.stickedMenu}
                        >

                            {category.subcategory.map((subcat, _index) => (
                                <Tab label={lang == 'en' ? subcat.Name_en : <Typography style={{ fontFamily: 'Adobe Arabic', fontSize: '22px' }}>{subcat.Name_ar}</Typography>} {...a11yProps(_index)} key={_index} />
                            ))}
                        </Tabs>

                        <Box id="two"
                            sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', height: 224 }}
                        >

                            <Grid container columns={{ xs: 1, sm: 1, md: 1 }}>
                                <Grid item xs={1} sm={1} md={1}>
                                    <SwipeableViews
                                        // axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                                        index={valueVertical}
                                        onChangeIndex={handleChangeVerticalIndex}
                                    >
                                        {category.subcategory.map((subcat, __index) => (
                                            <Grid container columns={{ xs: 1, sm: 1, md: 1 }} key={__index}>
                                                <Grid id="item" item xs={1} sm={1} md={1} key={__index} mb={5}>
                                                    {
                                                        subcat.product.map((prod, ___index) => (
                                                            <TabPanelContent
                                                                value={valueVertical}
                                                                index={__index}
                                                                key={___index}
                                                                product={prod}
                                                                lang={lang}
                                                                style={{ borderBottom: '1px solid #eee' }}>
                                                            </TabPanelContent>
                                                        ))
                                                    }
                                                </Grid>
                                            </Grid>
                                        ))}

                                    </SwipeableViews>
                                </Grid>
                            </Grid>
                        </Box>
                    </TabPanel>
                ))}
                {/* </SwipeableViews> */}
            </Box >
            <Box
                sx={{
                    backgroundColor: (theme) =>
                        theme.palette.mode === "light"
                            ? theme.palette.grey[200]
                            : theme.palette.grey[800],
                    p: 2,
                    position: 'fixed', // Set the position to fixed
                    left: 0, // Span the full width of the viewport
                    right: 0, // Span the full width of the viewport
                    bottom: 0, // Position the footer at the bottom
                    zIndex: 1, // Optionally, set a z-index
                }}
                component="footer"
            >
                <Container maxWidth="sm">
                    <Typography variant="body2" color="text.secondary" align="center">
                        {"Copyright © "}
                        <Link color="inherit" href="https://aramstech.com/" target="_blank" rel="noopener">
                            AramsTech.com
                        </Link>{" "}
                        {new Date().getFullYear()}
                        {"."}
                    </Typography>
                </Container>
            </Box>

        </>
    );
};

const mapStateToProps = (state) => {
    return state
}

const dispatchCompany = (dispatch) => {
    return {
        setCompanyData: (company, logo, primaryColor) => { dispatch({ type: 'SET_COMPANY_DATA', company: company, logo: logo, primaryColor: primaryColor }) },
        // setUserData: (token, user, lastActivityTime) => { dispatch({ type: 'SET_USER_DATA', token: token, user: user, lastActivityTime: lastActivityTime }) }
    }
}

export default connect(mapStateToProps, dispatchCompany)(Menu);
// export default Menu;