import React, { useEffect, useState, useRef } from 'react';
import { makeStyles } from "tss-react/mui";
import { connect } from 'react-redux';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ImageUploader from '../ImageUploader';

import {
    Grid,
    Box,
    TextField,
    Divider,
    Button,
    Paper,
    Stack,
    Switch,
    Autocomplete,
    Typography,
    IconButton
} from '@mui/material';
import { useHistory } from 'react-router-dom';
import { MuiChipsInput } from 'mui-chips-input';
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import CircularProgress from '@mui/material/CircularProgress';
import { NetworkCheckOutlined } from '@mui/icons-material';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const EditProduct = (props) => {
    const { loggedIn } = props;

    const history = useHistory();
    const [snackBar, setSnackBar] = useState({
        isOpen: false,
        status: '',
        message: '',
    });
    const [categories, setCategories] = useState([]); //categories from db as list
    const [subcategories, setSubcategories] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState(history.location.state.row);
    const [ingredients_en, setIngredients_en] = useState(selectedProduct.ingredients_en.map(item => item.Name));
    const [ingredients_ar, setIngredients_ar] = useState(selectedProduct.ingredients_ar.map(item => item.Name));

    const useStyles = makeStyles()(
        () => ({
            mainContainer: {
                display: 'flex',
                flexDirection: 'column',
                flexWrap: 'wrap',
            },
            BtnContainer: {
                display: 'flex',
                justifyContent: 'space-evenly',
                flexWrap: 'nowrap',
                flexDirection: 'row',
            },
            Btn: {
                minWidth: 150,
            },
            submitBtn: {
                color: '#FFF',
            },
            cancelBtn: {

            }

        })
    );
    const { classes } = useStyles();
    const getCategories = async () => {
        await fetch('/api/categories', {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${loggedIn.token}`
            }
        })
            .then(response => response.json())
            .then(data => {
                setCategories(data);
            });
    }
    const getSubcategories = async (CatID) => {
        await fetch('/api/subcategories_by_category?CatID=' + CatID, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${loggedIn.token}`
            },
        })
            .then(response => response.json())
            .then(data => {
                setSubcategories(data.subcategories);
            });
    }

    const handleImageChange = (image) => {
        setSelectedProduct({
            ...selectedProduct,
            Image: image
        });
    };
    const handleChangeIngredients_en = (newChips) => {
        setIngredients_en(newChips)
    }
    const handleChangeIngredients_ar = (newChips) => {
        setIngredients_ar(newChips)
    }

    const submitUpdateProduct = async () => {
        await fetch('/api/product/edit', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${loggedIn.token}`
            },
            body: JSON.stringify({
                product: selectedProduct,
                ingredients_en: ingredients_en,
                ingredients_ar: ingredients_ar
            })
        })
            .then(response => response.json())
            .then(data => {
                setSnackBar({
                    isOpen: true,
                    status: data.status,
                    message: data.message,
                });
                history.push('/products/list');
            });
    }
    // const clearForm = () => {
    //     setSelectedProduct({
    //         CatID: '',
    //         SubcatID: '',
    //         Name: '',
    //         Price: 0,
    //         Ingredients: [],
    //         Image: ''
    //     })
    // }

    useEffect(() => {
        // getEditedItem();
        getCategories();
        getSubcategories(selectedProduct.subcategory.CatID);


    }, []);

    return (
        <>
            <Box
                component="form"
                noValidate
                autoComplete="off"
                style={{ padding: 15 }}
            >
                <Grid container spacing={{ xs: 1, md: 1 }} columns={{ xs: 1, sm: 1, md: 4 }}>
                    <Grid item xs={1} sm={3} md={4}>
                        <Paper variant="outlined" style={{ padding: 15 }}>
                            <Grid container spacing={{ xs: 1, md: 4 }} columns={{ xs: 1, sm: 1, md: 12 }}>
                                <Grid item xs={1} sm={2} md={4}>
                                    <Typography variant='h5'><AddCircleOutlineIcon /> Edit Product</Typography>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item xs={1} sm={2} md={4}>
                        <Paper variant="outlined" style={{ padding: 15 }}>
                            <Grid container spacing={{ xs: 1, md: 1 }} columns={{ xs: 1, sm: 1, md: 6 }} className={classes.mainContainer}>
                                <Grid item xs={1} sm={1} md={6}>
                                    <Grid container spacing={{ xs: 1, md: 1 }} columns={{ xs: 1, sm: 1, md: 6 }}>
                                        <Grid item xs={1} sm={1} md={4.5}>
                                            <Grid container spacing={{ xs: 1, md: 2 }} columns={{ xs: 1, sm: 1, md: 3 }}>
                                                <Grid item xs={1} sm={1} md={3}>
                                                    <Grid container spacing={{ xs: 1, md: 1 }} columns={{ xs: 1, sm: 1, md: 3 }}>
                                                        <Grid item xs={1} sm={1} md={1}>
                                                            {categories ? (
                                                                <Autocomplete
                                                                    disablePortal
                                                                    size='small'
                                                                    id="category"
                                                                    loading={true}
                                                                    options={categories}
                                                                    defaultValue={selectedProduct ? selectedProduct?.subcategory?.category : null}
                                                                    isOptionEqualToValue={
                                                                        (option, value) => option?.CatID === value?.CatID
                                                                    }
                                                                    onChange={(event, value) => {
                                                                        setSelectedProduct({ ...selectedProduct, CatID: value.CatID });
                                                                        getSubcategories(value.CatID)
                                                                    }}
                                                                    getOptionLabel={option => option.Name_en}
                                                                    renderInput={(params) => <TextField {...params} label="Select Category" />}
                                                                />
                                                            ) : (
                                                                <CircularProgress size='1.5rem' />
                                                            )}
                                                        </Grid>
                                                        <Grid item xs={1} sm={1} md={1}>
                                                            <Autocomplete
                                                                disablePortal
                                                                size='small'
                                                                id="subcategory"
                                                                loading={true}
                                                                options={subcategories}
                                                                defaultValue={selectedProduct ? selectedProduct?.subcategory : null}
                                                                isOptionEqualToValue={
                                                                    (option, value) => option?.SubcatID === value?.SubcatID
                                                                } onChange={(event, value) => {
                                                                    setSelectedProduct({
                                                                        ...selectedProduct,
                                                                        SubcatID: value.SubcatID
                                                                    })

                                                                }}
                                                                getOptionLabel={option => option.Name_en}
                                                                renderInput={(params) => <TextField {...params} label="Select Subcategory" />}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={1} sm={1} md={1}>
                                                            <TextField
                                                                required
                                                                fullWidth
                                                                type='number'
                                                                id="outlined-required"
                                                                label="Product Order"
                                                                size='small'
                                                                value={selectedProduct.Order}
                                                                onChange={(event) => {
                                                                    setSelectedProduct({
                                                                        ...selectedProduct,
                                                                        Order: event.target.value
                                                                    })
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={1} sm={1} md={3}>
                                                    <Grid container spacing={{ xs: 1, md: 1 }} columns={{ xs: 1, sm: 1, md: 3 }}>
                                                        <Grid item xs={1} sm={1} md={1}>
                                                            <TextField
                                                                required
                                                                fullWidth
                                                                id="outlined-required"
                                                                label="Product Name En"
                                                                size='small'
                                                                value={selectedProduct.Name_en}
                                                                onChange={(event) => {
                                                                    setSelectedProduct({
                                                                        ...selectedProduct,
                                                                        Name_en: event.target.value
                                                                    })
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={1} sm={1} md={1}>
                                                            <TextField
                                                                required
                                                                fullWidth
                                                                id="outlined-required"
                                                                label="Product Name Ar"
                                                                size='small'
                                                                value={selectedProduct.Name_ar}
                                                                onChange={(event) => {
                                                                    setSelectedProduct({
                                                                        ...selectedProduct,
                                                                        Name_ar: event.target.value
                                                                    })
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={1} sm={1} md={1}>
                                                            <TextField
                                                                required
                                                                fullWidth
                                                                id="outlined-required"
                                                                label="Price"
                                                                size='small'
                                                                type='number'
                                                                value={selectedProduct.Price}
                                                                onChange={(event) => {
                                                                    setSelectedProduct({
                                                                        ...selectedProduct,
                                                                        Price: event.target.value
                                                                    })
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={1} sm={1} md={3}>
                                                    <Grid container spacing={{ xs: 1, md: 1 }} columns={{ xs: 1, sm: 1, md: 1 }}>
                                                        <Grid item xs={1} sm={1} md={1}>
                                                            <MuiChipsInput
                                                                fullWidth
                                                                label="Ingredients En"
                                                                size="small"
                                                                variant="outlined"
                                                                value={ingredients_en}
                                                                validate={(chipValue) => chipValue.length >= 3}
                                                                onChange={handleChangeIngredients_en} />
                                                        </Grid>
                                                        <Grid item xs={1} sm={1} md={1}>
                                                            <MuiChipsInput
                                                                fullWidth
                                                                label="Ingredients Ar"
                                                                placeholder='اكتب واضغط على إدخال'
                                                                size="small"
                                                                variant="outlined"
                                                                value={ingredients_ar}
                                                                validate={(chipValue) => chipValue.length >= 3}
                                                                onChange={handleChangeIngredients_ar}
                                                                xs={{ textAlign: 'right' }} />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                            </Grid>
                                        </Grid>
                                        <Grid item xs={1} sm={1} md={1.5}>
                                            <Grid container spacing={{ xs: 1, md: 1 }} columns={{ xs: 1, sm: 1, md: 1 }}>
                                                <Grid item xs={1} sm={1} md={1}>
                                                    <ImageUploader image={selectedProduct.Image} onChange={handleImageChange} />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <hr />




                                <Grid item xs={1} sm={1} md={6} className={classes.BtnContainer}>
                                    <Grid container spacing={{ xs: 1, md: 1 }} columns={{ xs: 1, sm: 1, md: 1 }}>
                                        <Grid item xs={1} sm={1} md={1} style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                                            <Button variant="contained" onClick={submitUpdateProduct} className={[classes.Btn, classes.submitBtn].join(' ')}>Edit Item</Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid >


            </Box >
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={snackBar.isOpen}
                autoHideDuration={6000}
                onClose={() => setSnackBar({ ...snackBar, isOpen: false })}
                message={snackBar.message}
            >
                <Alert severity={snackBar.status} sx={{ width: '100%' }}>
                    {snackBar.message}
                </Alert>
            </Snackbar>
        </>
    );
};


const mapStateToProps = (state) => {
    return state
}
export default connect(mapStateToProps, null)(EditProduct);