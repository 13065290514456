import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Container } from '@mui/material';
import { makeStyles } from "tss-react/mui";
import Grid from '@mui/material/Grid';
import SidebarNav from '../components/SidebarNav';
import Header from '../components/Header';
import { connect } from 'react-redux';
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';


const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const DashboardContainer = (props) => {
    const history = useHistory();
    const location = useLocation();
    const [snackBar, setSnackBar] = useState({
        isOpen: false,
        status: '',
        message: '',
    });
    const logout = async () => {
        await fetch('/api/logout', {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${props.loggedIn.token}`
            }
        })
            .then(response => response.json())
            .then(data => {
                if (data.status == 'success') {
                    props.logoutUser(props.loggedIn);
                    history.push('/Login');
                }
                setSnackBar({
                    isOpen: true,
                    status: data.status,
                    message: data.message,
                });
            });

    }

    const checkSession = () => {
        const currentTime = new Date().getTime();
        const lastActivityTime = new Date(props.loggedIn.lastActivityTime).getTime();
        if ((currentTime - lastActivityTime) / 60000 >= 60) {
            logout();
        } else {
            props.updateActivityTime(new Date().toISOString());
        }
    }

    useEffect(() => {
        checkSession();
    }, [location])


    return (
        <>
            <Header />
            <Grid container spacing={{ xs: 1, md: 1 }} columns={{ xs: 12, sm: 12, md: 12 }}>
                <Grid item xs={1} sm={1} md={2}>
                    <SidebarNav />
                </Grid>
                <Grid item xs={11} sm={11} md={10}>
                    <Container maxWidth="xl">{props.children}</Container>
                </Grid>
            </Grid>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={snackBar.isOpen}
                autoHideDuration={6000}
                onClose={() => setSnackBar({ ...snackBar, isOpen: false })}
                message={snackBar.message}
            >
                <Alert severity={snackBar.status} sx={{ width: '100%' }}>
                    {snackBar.message}
                </Alert>
            </Snackbar>
        </>
    );
};
const mapStateToProps = (state) => {
    return state
}
const dispatchCompany = (dispatch) => {
    return {
        updateActivityTime: (lastActivityTime) => { dispatch({ type: 'UPDATE_ACTIVITY_TIME', lastActivityTime: lastActivityTime }) },
        logoutUser: (loggedIn) => { dispatch({ type: 'LOGOUT_USER', token: loggedIn.token }) }
    }
}

export default connect(mapStateToProps, dispatchCompany)(DashboardContainer);
