import React, { useEffect, useState } from 'react';
import { makeStyles } from "tss-react/mui";

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import CircularProgress from '@mui/material/CircularProgress';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Autocomplete } from '@mui/material';
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const AddSubcategory = (props) => {
    const { loggedIn } = props;
    const [isLoading, setIsLoading] = useState(false);
    const [snackBar, setSnackBar] = useState({
        isOpen: false,
        status: '',
        message: '',
    });
    const [subcategory, setSubcategory] = useState({
        catId: '',
        subcategory_en: '',
        subcategory_ar: '',
        order: 0
    });
    const [categories, setCategories] = useState([]);

    const useStyles = makeStyles()(
        () => ({
            BtnContainer: {
                display: 'flex',
                justifyContent: 'space-evenly',
                flexWrap: 'nowrap',
                flexDirection: 'row',
            },
            Btn: {
                width: 150,
            },
            submitBtn: {
                color: '#FFF',
            },
            cancelBtn: {

            }

        })
    );
    const { classes } = useStyles();

    const getCategories = async () => {
        setIsLoading(true);
        await fetch('/api/categories', {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${loggedIn.token}`
            },
        })
            .then(response => response.json())
            .then(data => {
                setIsLoading(false);
                setCategories(data);
            });
    }
    const submit = async () => {
        if (_.isEmpty(JSON.stringify(subcategory.catId)) || _.isEmpty(subcategory.subcategory)) {
            setSnackBar({
                isOpen: true,
                status: 'error',
                message: 'Fill the required field',
            });
            return;
        }
        setIsLoading(true);
        await fetch('/api/subcategory', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${loggedIn.token}`
            },
            body: JSON.stringify({
                subcategory: subcategory
            })
        })
            .then(response => response.json())
            .then(data => {
                setIsLoading(false);
                setSubcategory({
                    catId: '',
                    subcategory: ''
                });
                setSnackBar({
                    isOpen: true,
                    status: data.status,
                    message: data.message,
                });
            });
    }
    useEffect(() => {
        getCategories();
    }, [])
    return (
        <>
            {isLoading && <Box sx={{ position: 'absolute', right: '20px', top: '80px' }}>
                <CircularProgress />
            </Box>}
            <Box
                component="form"

                noValidate
                autoComplete="off"
                style={{ padding: 15 }}
            >
                <Grid container spacing={{ xs: 1, md: 1 }} columns={{ xs: 1, sm: 1, md: 1 }}>
                    <Grid item xs={1} sm={2} md={2}>
                        <Paper variant="outlined" style={{ padding: 15 }}>
                            <Typography variant='h5'><AddCircleOutlineIcon /> Add Subcategory</Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={1} sm={2} md={2}>
                        <Paper variant="outlined" style={{ padding: 15 }}>
                            <Grid container spacing={{ xs: 1, md: 1 }} columns={{ xs: 1, sm: 1, md: 2 }}>
                                <Grid item xs={1} sm={1} md={1}>
                                    <Autocomplete
                                        fullWidth
                                        disablePortal
                                        id="category"
                                        loading={true}
                                        options={categories}
                                        // value={newItem.CatID ? categories.find(category => category.CatID === newItem.CatID) : null}
                                        onChange={(event, value) => {
                                            setSubcategory({
                                                ...subcategory,
                                                catId: value.CatID
                                            })
                                        }}
                                        getOptionLabel={option => option.Name_en}
                                        renderInput={(params) => <TextField {...params} label="Select Category" />}
                                    />
                                </Grid>
                                <Grid item xs={1} sm={1} md={1}>
                                    <TextField
                                        fullWidth
                                        required
                                        type='number'
                                        id="outlined-required"
                                        label="Order By"
                                        value={subcategory.order}
                                        onChange={(event) => setSubcategory({
                                            ...subcategory,
                                            order: event.target.value
                                        })}
                                    />
                                </Grid>

                                <Grid item xs={1} sm={1} md={1}>
                                    <TextField
                                        fullWidth
                                        required
                                        id="outlined-required"
                                        label="Subcategory Name En"
                                        value={subcategory.subcategory_en}
                                        onChange={(event) => setSubcategory({
                                            ...subcategory,
                                            subcategory_en: event.target.value
                                        })}
                                    />
                                </Grid>
                                <Grid item xs={1} sm={1} md={1}>
                                    <TextField
                                        fullWidth
                                        required
                                        id="outlined-required"
                                        label="Subcategory Name Ar"
                                        value={subcategory.subcategory_ar}
                                        onChange={(event) => setSubcategory({
                                            ...subcategory,
                                            subcategory_ar: event.target.value
                                        })}
                                        sx={{direction:'rtl'}}
                                    />
                                </Grid>
                                
                                <Grid item xs={1} sm={1} md={2} className={classes.BtnContainer}>
                                    <Button variant="contained" className={[classes.Btn, classes.submitBtn].join(' ')} onClick={submit}>Add</Button>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>

            </Box>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={snackBar.isOpen}
                autoHideDuration={6000}
                onClose={() => setSnackBar({ ...snackBar, isOpen: false })}
                message={snackBar.message}
            >
                <Alert severity={snackBar.status} sx={{ width: '100%' }}>
                    {snackBar.message}
                </Alert>
            </Snackbar>
        </>
    );
};


const mapStateToProps = (state) => {
    return state
}

export default connect(mapStateToProps)(AddSubcategory);