import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Sidebar, Menu, MenuItem, SubMenu, useProSidebar } from 'react-pro-sidebar';
import CategoryIcon from '@mui/icons-material/Category';
import ExtensionIcon from '@mui/icons-material/Extension';
import InterestsIcon from '@mui/icons-material/Interests';
import FaceIcon from '@mui/icons-material/Face';
import SummarizeIcon from '@mui/icons-material/Summarize';
import DashboardIcon from '@mui/icons-material/Dashboard';
import SettingsIcon from '@mui/icons-material/Settings';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import AssignmentIcon from '@mui/icons-material/Assignment';
import SquareFootIcon from '@mui/icons-material/SquareFoot';
import { connect } from 'react-redux';
import { Divider } from '@mui/material';

const SidebarNav = (props) => {
    const { loggedIn } = props;
    const { collapseSidebar } = useProSidebar();
    const [collapsedWidth, setCollapsedWidth] = useState('80px');
    //function to collapse sidebar on mobile view
    function toggleSidebar() {
        const sidebar = document.querySelector('.ps-sidebar-root');
        // if (window.innerWidth <= 768) {
        if (window.innerWidth <= 1260) {
            sidebar.classList.add('ps-collapsed');
            if (window.innerWidth <= 700) {
                setCollapsedWidth('0px');
            } else {
                setCollapsedWidth('80px');
            }
        } else {
            sidebar.classList.remove('ps-collapsed');
            setCollapsedWidth('80px');
        }
    }
    useEffect(() => {
        window.addEventListener('resize', toggleSidebar);
    }, []);
    return (
        <div style={{ display: 'flex', minHeight: '100vh' }}>
            <Sidebar collapsedWidth={collapsedWidth}>
                <Menu >
                    <MenuItem icon={<DashboardIcon />} routerLink={<Link to="/dashboard" />}> Dashboard</MenuItem>
                    <SubMenu label="Categories" icon={<CategoryIcon />}>
                        <MenuItem icon={<FormatListBulletedIcon />} routerLink={<Link to="/categories/list" />}> List</MenuItem>
                        <MenuItem icon={<AddCircleOutlineIcon />} routerLink={<Link to="/categories/add" />}> Add </MenuItem>
                    </SubMenu>
                    <SubMenu label="Subcategories" icon={<SquareFootIcon />}>
                        <MenuItem icon={<FormatListBulletedIcon />} routerLink={<Link to="/subcategories/list" />}> List</MenuItem>
                        <MenuItem icon={<AddCircleOutlineIcon />} routerLink={<Link to="/subcategories/add" />}> Add </MenuItem>
                    </SubMenu>

                    
                    
                    <SubMenu label="Items" icon={<ExtensionIcon />}>
                        <MenuItem icon={<FormatListBulletedIcon />} routerLink={<Link to="/products/list" />}> List</MenuItem>
                        <MenuItem icon={<AddCircleOutlineIcon />} routerLink={<Link to="/product/add" />}> Add </MenuItem>
                    </SubMenu>
                    
                    <Divider variant="middle" style={{ borderBottomWidth: '3px', borderColor: 'var(--primary-color-stock)' }} />
                    
                    <MenuItem icon={<SettingsIcon />} routerLink={<Link to="/settings" />}> Settings</MenuItem>

                </Menu>
            </Sidebar>
        </div>
    );
};


const mapStateToProps = (state) => {
    return state
}

export default connect(mapStateToProps)(SidebarNav);
