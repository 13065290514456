import React from 'react';
import { makeStyles } from "tss-react/mui";
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';

const DeleteConfirmationDialog = ({ handleOpen, handleCloseClick, handleConfirmClick }) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const useStyles = makeStyles()(
        () => ({
            mainContainer: {
                display: 'flex',
                flexDirection: 'column',
                flexWrap: 'wrap',
            },
            BtnContainer: {
                display: 'flex',
                justifyContent: 'space-evenly',
                flexWrap: 'nowrap',
                flexDirection: 'row',
            },
            Btn: {
                width: 150,
            },
            submitBtn: {
                color: '#FFF',
            },
            cancelBtn: {

            }

        })
    );
    const { classes } = useStyles();
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 720,
        bgcolor: 'background.paper',
        border: '1px solid var(--primary-color-stock)',
        borderRadius: '15px',
        boxShadow: 24,
        pt: 3,
        px: 4,
        pb: 3,
    };
    const handleBackdropClick = (e) => {
        //these fail to keep the modal open
        e.stopPropagation();
        return false;
      };
    return (
        <>
            <Dialog
                disableEscapeKeyDown={true}
                fullScreen={fullScreen}
                open={handleOpen}
                onClose={handleCloseClick}
                onBackdropClick={handleBackdropClick}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">
                    Confirmation Dialog
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure ?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleCloseClick}>
                        No
                    </Button>
                    <Button onClick={handleConfirmClick} autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default DeleteConfirmationDialog;