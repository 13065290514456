import React, { useEffect, useState, useMemo } from 'react';
import { makeStyles } from "tss-react/mui";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import { OutlinedInput, TextField, InputAdornment, IconButton, FormControl, InputLabel } from '@mui/material';
import Typography from '@mui/material/Typography';
import Autocomplete from '@mui/material/Autocomplete';

import 'react-photo-view/dist/react-photo-view.css';
import _ from 'lodash';

import BarChartIcon from '@mui/icons-material/BarChart';

import 'bootstrap/dist/css/bootstrap.min.css';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ImageUploaderSettings from '../components/ImageUploaderSettings';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';
import SettingsIcon from '@mui/icons-material/Settings';
import { connect } from 'react-redux';
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import CircularProgress from '@mui/material/CircularProgress';
import { ChromePicker } from 'react-color';
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Settings = (props) => {
    const { loggedIn } = props;
    const [isLoading, setIsLoading] = useState(false);
    const LangOptions = ['en', 'ar'];
    const [snackBar, setSnackBar] = useState({
        isOpen: false,
        status: '',
        message: '',
    });
    const [user, setUser] = useState({
        UserID: '',
        Name: '',
        Username: '',
        Role: '',
        Password: ''
    })
    const [settings, setSettings] = useState({
        Name: '',
        Logo: '',
        Banner: '',
        PrimaryColor: '',
        PrimaryLanguage: '',
        Address: '',
        Slogon: '',
        Phone: ''
    });
    const [showPassword, setShowPassword] = useState(false);
    const useStyles = makeStyles()(
        () => ({
            mainContainer: {
                display: 'flex',
                flexDirection: 'column',
                flexWrap: 'wrap',
            },
            BtnContainer: {
                display: 'flex',
                justifyContent: 'space-evenly',
                flexWrap: 'nowrap',
                flexDirection: 'row',
            },
            Btn: {
                width: 150,
            },
            submitBtn: {
                color: '#FFF',
            },
            cancelBtn: {

            }

        })
    );

    const { classes } = useStyles();
    const handleLogoChange = (file) => {
        setSettings({
            ...settings,
            Logo: file
        })
    }
    const handleBannerChange = (file) => {
        setSettings({
            ...settings,
            Banner: file
        })
    }

    const getSettings = async () => {
        await fetch('/api/settings', {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${loggedIn.token}`
            }
        })
            .then(response => response.json())
            .then(data => {
                setSettings(data);
            });
    }
    const getUser = async () => {
        await fetch('/api/user', {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${loggedIn.token}`
            }
        })
            .then(response => response.json())
            .then(data => {
                setUser(data);
            });
    }
    const handleClickShowPassword = () => {
        setShowPassword(prev => !prev);
    }
    const handleUpdateUser = async () => {
        setIsLoading(true)
        //user/edit
        await fetch('/api/user/edit', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${loggedIn.token}`
            },
            body: JSON.stringify({
                user: user
            })
        })
            .then(response => response.json())
            .then(data => {
                setIsLoading(false)
                props.editUserData(data.user)
                setSnackBar({
                    isOpen: true,
                    status: data.status,
                    message: data.message,
                });
            });
    }
    const submitCompanySettings = async () => {
        if (_.isEmpty(settings.Logo)) {
            setSnackBar({
                isOpen: true,
                status: 'error',
                message: 'Select Logo',
            });
            return;
        }
        setIsLoading(true)
        await fetch('/api/settings', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${loggedIn.token}`
            },
            body: JSON.stringify({
                settings: settings
            })
        })
            .then(response => response.json())
            .then(data => {
                setIsLoading(false);
                props.setCompanyData(settings.Company, settings.Logo, settings.PrimaryColor);
                document.documentElement.style.setProperty('--primary-color-stock', settings.PrimaryColor);
                setSnackBar({
                    isOpen: true,
                    status: data.status,
                    message: data.message,
                });
            });
    }
    useEffect(() => {
        getSettings();
        getUser();
    }, [])
    return (
        <>
            {isLoading && <Box sx={{ position: 'absolute', right: '20px', top: '80px' }}>
                <CircularProgress />
            </Box>}
            <Box
                component="form"

                noValidate
                autoComplete="off"
                style={{ padding: 15 }}
            >
                <Grid container spacing={{ xs: 1, md: 1 }} columns={{ xs: 1, sm: 1, md: 1 }}>
                    <Grid item xs={1} sm={2} md={2}>
                        <Paper variant="outlined" style={{ padding: 15 }}>
                            <Typography variant='h5'><SettingsIcon /> Settings</Typography>
                        </Paper>
                    </Grid>

                    <Grid item xs={1} sm={2} md={2}>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography>Account Settings</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container spacing={{ xs: 1, md: 1 }} columns={{ xs: 1, sm: 1, md: 5 }}>
                                    <Grid item xs={1} sm={1} md={2}>
                                        <TextField
                                            fullWidth
                                            label="Full Name"
                                            size="small"
                                            value={user.Name}
                                            onChange={(event) => setUser({
                                                ...user,
                                                Name: event.target.value
                                            })}
                                        />
                                    </Grid>
                                    <Grid item xs={1} sm={1} md={2}>
                                        <TextField
                                            fullWidth
                                            label="Username"
                                            size="small"
                                            value={user.Username}
                                            InputProps={{
                                                readOnly: true
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={1} sm={1} md={2}>
                                        <FormControl fullWidth variant="outlined">
                                            <InputLabel htmlFor="outlined-adornment-password" size='small'>Password</InputLabel>
                                            <OutlinedInput

                                                type={showPassword ? 'text' : 'password'}
                                                label="Password"
                                                size="small"
                                                onChange={(event) => setUser({
                                                    ...user,
                                                    Password: event.target.value
                                                })}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleClickShowPassword}
                                                            edge="end"
                                                        >
                                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={1} sm={1} md={2}>
                                        <TextField
                                            fullWidth
                                            label="Role"
                                            size="small"
                                            value={user.Role}
                                            InputProps={{
                                                readOnly: true
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={1} sm={1} md={1}>
                                        <Button variant='contained' onClick={handleUpdateUser}>Update</Button>
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel2a-content"
                                id="panel2a-header"
                            >
                                <Typography>Company Settings</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container spacing={{ xs: 1, md: 1 }} columns={{ xs: 1, sm: 1, md: 4 }}>
                                    <Grid item xs={1} sm={1} md={2}>
                                        <Grid container spacing={{ xs: 1, md: 1 }} columns={{ xs: 1, sm: 1, md: 2 }}>
                                            <Grid item xs={1} sm={1} md={2}>
                                                <Typography variant='h6'>
                                                    Resto Name
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={1} sm={1} md={2}>
                                                <TextField
                                                    fullWidth
                                                    size="small"
                                                    required
                                                    id="outlined-required"
                                                    value={settings.Name}
                                                    onChange={(event) => setSettings({
                                                        ...settings,
                                                        Name: event.target.value
                                                    })}
                                                />
                                            </Grid>
                                            <Grid item xs={1} sm={1} md={2}>
                                                <Typography variant='h6'>
                                                    Logo
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={1} sm={1} md={2}>
                                                <ImageUploaderSettings image={settings.Logo} onChange={(file) => handleLogoChange(file)} />
                                            </Grid>
                                            <Grid item xs={1} sm={1} md={2}>
                                                <Typography variant='h6'>
                                                    Banner
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={1} sm={1} md={2}>
                                                <ImageUploaderSettings image={settings.Banner} onChange={(file) => handleBannerChange(file)} />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={1} sm={1} md={2}>
                                        <Grid container spacing={{ xs: 1, md: 1 }} columns={{ xs: 1, sm: 1, md: 2 }}>
                                            <Grid item xs={1} sm={1} md={2}>
                                                <Typography variant='h6'>
                                                    Slogon
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={1} sm={1} md={2}>
                                                <TextField
                                                    fullWidth
                                                    size="small"
                                                    required
                                                    id="outlined-required"
                                                    value={settings.Slogon}
                                                    onChange={(event) => setSettings({
                                                        ...settings,
                                                        Slogon: event.target.value
                                                    })}
                                                />
                                            </Grid>
                                            <Grid item xs={1} sm={1} md={2}>
                                                <Typography variant='h6'>
                                                    Address
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={1} sm={1} md={2}>
                                                <TextField
                                                    fullWidth
                                                    size="small"
                                                    required
                                                    id="outlined-required"
                                                    value={settings.Address}
                                                    onChange={(event) => setSettings({
                                                        ...settings,
                                                        Address: event.target.value
                                                    })}
                                                />
                                            </Grid>
                                            <Grid item xs={1} sm={1} md={2}>
                                                <Typography variant='h6'>
                                                    Phone
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={1} sm={1} md={2}>
                                                <TextField
                                                    fullWidth
                                                    size="small"
                                                    required
                                                    id="outlined-required"
                                                    value={settings.Phone}
                                                    onChange={(event) => setSettings({
                                                        ...settings,
                                                        Phone: event.target.value
                                                    })}
                                                />
                                            </Grid>
                                            <Grid item xs={1} sm={1} md={2}>
                                                <Typography variant='h6'>
                                                    Primary Language
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={1} sm={1} md={2}>
                                                <Autocomplete
                                                    disablePortal
                                                    id="combo-box-demo"
                                                    options={LangOptions}
                                                    value={settings.PrimaryLanguage}
                                                    onChange={(event, value) => {
                                                        setSettings({
                                                            ...settings,
                                                            PrimaryLanguage: value
                                                        })
                                                    }}
                                                    // sx={{ width: 300 }}
                                                    renderInput={(params) => <TextField {...params} label="Choose Language" />}
                                                />
                                            </Grid>
                                            <Grid item xs={1} sm={1} md={2}>
                                                <Typography variant='h6'>
                                                    Theme Color
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={1} sm={1} md={2}>
                                                <ChromePicker color={settings.PrimaryColor} onChange={(value) => setSettings({
                                                    ...settings,
                                                    PrimaryColor: value.hex
                                                })} />
                                            </Grid>

                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={{ xs: 1, md: 1 }} columns={{ xs: 1, sm: 1, md: 1 }}>
                                    <Grid item xs={1} sm={1} md={1}>
                                        <Button variant='contained' onClick={submitCompanySettings}>Update</Button>
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                </Grid>
                <Snackbar
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    open={snackBar.isOpen}
                    autoHideDuration={6000}
                    onClose={() => setSnackBar({ ...snackBar, isOpen: false })}
                    message={snackBar.message}
                >
                    <Alert severity={snackBar.status} sx={{ width: '100%' }}>
                        {snackBar.message}
                    </Alert>
                </Snackbar>
            </Box>
        </>
    );
};

const dispatchCompany = (dispatch) => {
    return {
        setCompanyData: (company, logo, primaryColor) => { dispatch({ type: 'SET_COMPANY_DATA', company: company, logo: logo, primaryColor: primaryColor }) },
        editUserData: (user) => { dispatch({ type: 'EDIT_USER_DATA', user: user }) }
    }
}
const mapStateToProps = (state) => {
    return state
}
export default connect(mapStateToProps, dispatchCompany)(Settings);