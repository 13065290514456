import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { connect } from 'react-redux';
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import CircularProgress from '@mui/material/CircularProgress';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" target="_blank" rel="noreferrer" href="https://aramstech.com/">
                Arams Tech
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}



const Login = (props) => {
    const history = useHistory();
    const { companyData, loggedIn } = props;
    const { settings, setSettings } = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [snackBar, setSnackBar] = useState({
        isOpen: false,
        status: '',
        message: '',
    });

    const handleSubmit = async () => {
        setIsLoading(true);
        await fetch('/api/login', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                username: username,
                password: password
            })
        })
            .then(response => response.json())
            .then(data => {
                setIsLoading(false);
                if (data.status == 'success') {
                    props.setUserData(data?.token, data?.user, new Date().toISOString());
                    props.setCompanyData(data?.settings?.Name, data?.settings?.Logo, data?.settings?.PrimaryColor);
                    document.documentElement.style.setProperty('--primary-color-stock', data?.settings?.PrimaryColor);
                }
                setSnackBar({
                    isOpen: true,
                    status: data.status,
                    message: data.message,
                });
            });
    };

    useEffect(() => {
        if (loggedIn.status == true) {
            history.push('/dashboard')
        }
    }, [loggedIn.status]);

    return (
        <>
            {isLoading && <Box sx={{ position: 'absolute', right: '20px', top: '80px' }}>
                <CircularProgress />
            </Box>}
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                {companyData.logo && <Grid container columns={1} justify="center" alignItems='center'>
                    <Grid item style={{ margin: '0 auto' }}>
                        <img src={companyData.logo} width={300} />
                    </Grid>
                </Grid>}
                <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Sign in
                </Typography>
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="username"
                        label="Username"
                        name="username"
                        autoComplete="username"
                        autoFocus
                        onChange={(event) => setUsername(event.target.value)}
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        onChange={(event) => setPassword(event.target.value)}
                    />
                    <Button
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                        onClick={handleSubmit}
                    >
                        Sign In
                    </Button>
                </Box>
            </Box>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={snackBar.isOpen}
                autoHideDuration={6000}
                onClose={() => setSnackBar({ ...snackBar, isOpen: false })}
                message={snackBar.message}
            >
                <Alert severity={snackBar.status} sx={{ width: '100%' }}>
                    {snackBar.message}
                </Alert>
            </Snackbar>
            <Copyright />
        </>
    );
};
const mapStateToProps = (state) => {
    return state
}

const dispatchCompany = (dispatch) => {
    return {
        setCompanyData: (company, logo, primaryColor) => { dispatch({ type: 'SET_COMPANY_DATA', company: company, logo: logo, primaryColor: primaryColor }) },
        setUserData: (token, user, lastActivityTime) => { dispatch({ type: 'SET_USER_DATA', token: token, user: user, lastActivityTime: lastActivityTime }) }
    }
}

export default connect(mapStateToProps, dispatchCompany)(Login);