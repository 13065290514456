const initState = {
    companyData: {
        company: '',
        logo: '',
        primaryColor: ''
    },
    loggedIn: {
        status: false,
        token: '',
        user: '',
        lastActivityTime: ''
    },
    autoSaveItem: null
}

const rootReducer = (state = initState, action) => {
    if (action.type == 'SET_COMPANY_DATA') {
        return {
            ...state,
            companyData: {
                company: action.company,
                logo: action.logo,
                primaryColor: action.primaryColor
            }
        }
    }
    if (action.type == 'AUTO_SAVE_NEW_ITEM') {
        return {
            ...state,
            autoSaveItem: action.savedItem
        }
    }
    if (action.type == 'SET_USER_DATA') {
        return {
            ...state,
            loggedIn: {
                status: true,
                token: action.token,
                user: action.user,
                lastActivityTime: action.lastActivityTime
            }
        }
    }
    if (action.type == 'EDIT_USER_DATA') {
        return {
            ...state,
            loggedIn: {
                ...state.loggedIn,
                status: true,
                user: action.user
            }
        }
    }
    if (action.type == 'UPDATE_ACTIVITY_TIME') {
        return {
            ...state,
            loggedIn: {
                ...state.loggedIn,
                lastActivityTime: action.lastActivityTime
            }
        }
    }
    if (action.type == 'LOGOUT_USER') {
        return {
            ...state,
            loggedIn: {
                status: false,
                token: '',
                user: '',
                lastActivityTime: ''
            }
        }
    }
    return state;
}


export default rootReducer;