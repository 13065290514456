import React, { useState, useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import DashboardContainer from '../containers/DashboardContainer';
import { ProSidebarProvider } from 'react-pro-sidebar';
// import { connect } from 'react-redux';
import { useSelector } from 'react-redux';

const PrivateRoute = ({ children, ...rest }) => {
  const isAuthenticated = useSelector((state) => state.loggedIn.status);
  // const [isAuthenticated, setIsAuthenticated] = useState(false);
  


  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          <ProSidebarProvider>
            <DashboardContainer>{children}</DashboardContainer>
          </ProSidebarProvider>
        ) : (
          <Redirect to="/login" /> // Redirect to login page or any desired route
        )
      }
    />
  );
};


// const mapStateToProps = (state) => {
//   return state
// }

// export default connect(mapStateToProps, null)(PrivateRoute);
export default PrivateRoute;
