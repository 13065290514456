import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from "tss-react/mui";
import Grid from '@mui/material/Grid';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { connect } from 'react-redux';
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { Divider } from '@mui/material';


const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const Home = (props) => {
    const [snackBar, setSnackBar] = useState({
        isOpen: false,
        status: '',
        message: '',
    });
    const [settings, setSettings] = useState({
        Company: '',
        Logo: '',
        primaryColor: ''
    });
    const { loggedIn } = props;
    const useStyles = makeStyles()(
        () => ({
            container: {
                minWidth: "100%",
                minHeight: "100vh",
                display: 'flex',
                flexDirection: 'column',
                flexWrap: 'nowrap',
                justifyContent: 'space-evenly',
                alignItems: 'center'
            },
            headerContainer: {
                display: 'flex',
                flexWrap: 'wrap',
                alignContent: 'center',
                minWidth: '100%',
                minHeight: '40vh',
            },
            header: {
                display: 'flex',
                flexDirection: 'column',

            },
            menu: {
                flexDirection: 'row',
                justifyContent: 'space-around',
            },
            homeIcon: {
                textAlign: 'center',
                fontSize: '130px',
                color: '#F00',
            },
            cards: {
                display: 'flex',
                flexDirection: 'column-reverse',
                justifyContent: 'space-around',
                alignItems: 'center',

            },
            Logo: {
                maxHeight: '400px'
            }

        })
    );
    const { classes } = useStyles();
    const history = useHistory();
    const enterAsGuest = () => {
        history.push('/dashboard');
    }
    const loginAsAdmin = () => {
        history.push('/Login');
    }
    const logout = async () => {
        await fetch('/api/logout', {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${loggedIn.token}`
            }
        })
            .then(response => response.json())
            .then(data => {
                if (data.status == 'success') {
                    props.logoutUser(loggedIn);
                    history.push('/');
                }
                setSnackBar({
                    isOpen: true,
                    status: data.status,
                    message: data.message,
                });
            });

    }
    const goToDashboard = () => {
        history.push('/dashboard');
    }
    const storeCompanyData = (company, logo, primarycolor) => {
        props.setCompanyData(company, logo, primarycolor);
    }

    const getSettings = async () => {
        await fetch('/api/settings')
            .then(response => response.json()
                .then(data => {
                    setSettings({
                        ...data,
                        Company: data.Company,
                        Logo: data.Logo,
                        PrimaryColor: data.PrimaryColor
                    });
                    storeCompanyData(data.Company, data.Logo, data.PrimaryColor);
                })
            )
    }

    useEffect(() => {
        getSettings();
    }, [])
    return (
        <>
            <Grid container spacing={{ xs: 1, md: 1 }} columns={{ xs: 1, sm: 1, md: 1 }} className={classes.container}>
                <Grid item xs={1} sm={2} md={2} className={classes.headerContainer}>
                    <Grid container className={classes.header}>
                        <Grid item style={{ textAlign: 'center', display: ' flex', flexWrap: 'nowrap', justifyContent: 'space-around' }}>
                            <img src={settings.Logo} className={classes.Logo} />
                        </Grid>
                        <Grid item>
                            <Typography variant='h4' align='center' style={{ color: 'var(--primary-color-stock)', fontSize: 40 }}>
                                {settings.Company}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Divider style={{ border: '10px solid var(--primary-color-stock)', borderRadius: '5px', width: '90%' }} />
                <Grid item xs={1} sm={2} md={2}>
                    {!loggedIn?.status && <Grid container spacing={{ xs: 1, md: 1 }} columns={{ xs: 1, sm: 2, md: 4 }} >
                        <Grid item xs={1} sm={2} md={2} className={classes.cards}>
                            <Button variant="outlined" onClick={enterAsGuest}>
                                <CardContent>
                                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                        Enter as
                                    </Typography>
                                    <Typography variant="h5" component="div">
                                        User
                                    </Typography>
                                </CardContent>
                            </Button>
                        </Grid>
                        <Grid item xs={1} sm={2} md={2} className={classes.cards}>
                            <Button variant="outlined" onClick={loginAsAdmin}>
                                <CardContent>
                                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                        Login as
                                    </Typography>
                                    <Typography variant="h5" component="div">
                                        Admin
                                    </Typography>
                                </CardContent>
                            </Button>
                        </Grid>
                    </Grid>}
                    {loggedIn?.status && <Grid container spacing={{ xs: 1, md: 1 }} columns={{ xs: 1, sm: 2, md: 4 }} >
                        <Grid item xs={1} sm={2} md={2} className={classes.cards}>
                            <Button fullWidth variant="outlined" onClick={goToDashboard}>
                                <CardContent>
                                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                        Go to
                                    </Typography>
                                    <Typography variant="h5" component="div">
                                        Dashboard
                                    </Typography>
                                </CardContent>
                            </Button>
                        </Grid>
                        <Grid item xs={1} sm={2} md={2} className={classes.cards}>
                            <Button fullWidth variant="outlined" onClick={logout}>
                                <CardContent>
                                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                        Logout
                                    </Typography>
                                    <Typography variant="h5" component="div">
                                        {loggedIn?.user?.Name}
                                    </Typography>
                                </CardContent>
                            </Button>
                        </Grid>
                    </Grid>}
                </Grid>
            </Grid>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={snackBar.isOpen}
                autoHideDuration={6000}
                onClose={() => setSnackBar({ ...snackBar, isOpen: false })}
                message={snackBar.message}
            >
                <Alert severity={snackBar.status} sx={{ width: '100%' }}>
                    {snackBar.message}
                </Alert>
            </Snackbar>
        </>
    );
};

const dispatchCompany = (dispatch) => {
    return {
        setCompanyData: (company, logo, primaryColor) => { dispatch({ type: 'SET_COMPANY_DATA', company: company, logo: logo, primaryColor: primaryColor }) },
        logoutUser: (loggedIn) => { dispatch({ type: 'LOGOUT_USER', token: loggedIn.token }) }
    }
}
const mapStateToProps = (state) => {
    return state
}
export default connect(mapStateToProps, dispatchCompany)(Home);
