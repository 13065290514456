import React, { useEffect, useState } from 'react';
import { makeStyles } from "tss-react/mui";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import StoreOutlinedIcon from '@mui/icons-material/StoreOutlined';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import Modal from '@mui/material/Modal';
import { Autocomplete } from '@mui/material';
import { connect } from 'react-redux';

const EditSubcategory = ({ modalData, handleOpenModal, handleCloseModal, handleEditSubcategoryModal, loggedIn }) => {

    const [subcategory, setSubcategory] = useState({
        CatID: '',
        Name_en: '',
        Name_ar: '',
        Order: 1
    });
    const [categories, setCategories] = useState([])
    const useStyles = makeStyles()(
        () => ({
            mainContainer: {
                display: 'flex',
                flexDirection: 'column',
                flexWrap: 'wrap',
            },
            BtnContainer: {
                display: 'flex',
                justifyContent: 'space-evenly',
                flexWrap: 'nowrap',
                flexDirection: 'row',
            },
            Btn: {
                width: 150,
            },
            submitBtn: {
                color: '#FFF',
            },
            cancelBtn: {

            }

        })
    );
    const { classes } = useStyles();
    const getCategories = async () => {
        await fetch('/api/categories', {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${loggedIn.token}`
            },
        })
            .then(response => response.json())
            .then(data => {
                setCategories(data);
            });
    }

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 500,
        bgcolor: 'background.paper',
        border: '1px solid var(--primary-color-stock)',
        borderRadius: '15px',
        boxShadow: 24,
        pt: 2,
        px: 4,
        pb: 3,
    };

    // useEffect(() => { getCategories() }, [subcategory])
    useEffect(() => {
        getCategories();
        // Set the subcategory state only if modalData is defined
        if (modalData) {
            setSubcategory(modalData);
        }
    }, [modalData]);
    return (
        <>
            <Modal
                hideBackdrop
                open={handleOpenModal}
                onClose={handleCloseModal}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
            >
                <Box sx={{ ...style }}>
                    <Box
                        component="form"

                        noValidate
                        autoComplete="off"
                        style={{ padding: 15 }}
                    >
                        <Grid container spacing={{ xs: 1, md: 1 }} columns={{ xs: 1, sm: 1, md: 1 }}>
                            <Grid item xs={1} sm={2} md={2}>
                                <Paper variant="outlined" style={{ padding: 15 }}>
                                    <Typography variant='h5'><EditRoundedIcon /> Edit Subcategory</Typography>
                                </Paper>
                            </Grid>
                            <Grid item xs={1} sm={2} md={2}>
                                <Paper variant="outlined" style={{ padding: 15 }}>
                                    <Grid container spacing={{ xs: 1, md: 1 }} columns={{ xs: 1, sm: 1, md: 1 }} className={classes.mainContainer}>
                                        <Grid item xs={1} sm={1} md={1}>
                                            <Autocomplete
                                                fullWidth
                                                disablePortal
                                                id="category"
                                                options={categories}
                                                defaultValue={modalData ? modalData.category : null} // Use defaultValue for uncontrolled component
                                                onChange={(event, value) => {
                                                    setSubcategory({
                                                        ...subcategory,
                                                        CatID: value.CatID
                                                    }); // Update the state when an option is selected
                                                }}
                                                isOptionEqualToValue={
                                                    (option, value) => option?.CatID === value?.CatID
                                                }
                                                getOptionLabel={(option) => option.Name_en}
                                                renderInput={(params) => <TextField {...params} label="Select Category" />}
                                            />
                                        </Grid>
                                        <Grid item xs={1} sm={1} md={1} style={{ display: 'flex' }}>
                                            <TextField
                                                fullWidth
                                                required
                                                id="outlined-required"
                                                label="Subcategory Name En"
                                                value={subcategory.Name_en}
                                                onChange={(event) => setSubcategory({
                                                    ...subcategory,
                                                    Name_en: event.target.value
                                                })}
                                            />
                                        </Grid>
                                        <Grid item xs={1} sm={1} md={1} style={{ display: 'flex' }}>
                                            <TextField
                                                fullWidth
                                                required
                                                id="outlined-required"
                                                label="Subcategory Name Ar"
                                                value={subcategory.Name_ar}
                                                onChange={(event) => setSubcategory({
                                                    ...subcategory,
                                                    Name_ar: event.target.value
                                                })}
                                            />
                                        </Grid>
                                        <Grid item xs={1} sm={1} md={1} style={{ display: 'flex' }}>
                                            <TextField
                                                fullWidth
                                                required
                                                type='number'
                                                id="outlined-required"
                                                label="Order By"
                                                value={subcategory.Order}
                                                onChange={(event) => {
                                                    console.log(event.target.value)
                                                    setSubcategory({
                                                        ...subcategory,
                                                        Order: event.target.value
                                                    })
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={1} sm={1} md={2} className={classes.BtnContainer}>
                                            <Button variant="contained" onClick={() => { handleEditSubcategoryModal(subcategory) }} className={[classes.Btn, classes.submitBtn].join(' ')}>Edit</Button>
                                            <Button variant="outlined" onClick={handleCloseModal} className={[classes.Btn, classes.cancelBtn].join(' ')}>Cancel</Button>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                        </Grid>

                    </Box>
                </Box>
            </Modal>


        </>
    );
};

const mapStateToProps = (state) => {
    return state
}
export default connect(mapStateToProps)(EditSubcategory);
// export default EditSubcategory;
