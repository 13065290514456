import React, { useState } from 'react';
import { makeStyles } from "tss-react/mui";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import StoreOutlinedIcon from '@mui/icons-material/StoreOutlined';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import Modal from '@mui/material/Modal';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import * as Icons from '@mui/icons-material';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const EditCategory = ({ modalData, handleOpenModal, handleCloseModal, handleEditCategory }) => {

    const [snackBar, setSnackBar] = useState({
        isOpen: false,
        status: '',
        message: '',
    });

    const [category, setCategory] = useState(modalData ? modalData : {
        Name_en: '',
        Name_ar: '',
        Icon: ''
    });
    const [editBtnDisabled, setEditBtnDisabled] = useState(false);
    const useStyles = makeStyles()(
        () => ({
            mainContainer: {
                display: 'flex',
                flexDirection: 'column',
                flexWrap: 'wrap',
            },
            BtnContainer: {
                display: 'flex',
                justifyContent: 'space-evenly',
                flexWrap: 'nowrap',
                flexDirection: 'row',
            },
            Btn: {
                width: 150,
            },
            submitBtn: {
                color: '#FFF',
            },
            cancelBtn: {

            }

        })
    );
    const { classes } = useStyles();
    const iconNames = [
        'EmojiFoodBeverage',
        'Fastfood',
        'FoodBank',
        'Coffee',
        'Icecream',
        'KebabDining',
        'Cake',
        'LocalDining',
        'Restaurant',
        'RestaurantMenu',
        'LocalBar',
        'LocalCafe',
        'Nightlife',
        'LocalPizza',
        'BakeryDining',
        'LunchDining',
        'SmokingRooms'
    ];

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 500,
        bgcolor: 'background.paper',
        border: '1px solid var(--primary-color-stock)',
        borderRadius: '15px',
        boxShadow: 24,
        pt: 2,
        px: 4,
        pb: 3,
    };
    const handleIconSelection = (iconName) => {
        setCategory({
            ...category,
            Icon: iconName
        });
    };
    return (
        <>
            <Modal
                hideBackdrop
                open={true}
                onClose={handleCloseModal}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
            >
                <Box sx={{ ...style }}>
                    <Box
                        component="form"

                        noValidate
                        autoComplete="off"
                        style={{ padding: 15 }}
                    >
                        <Grid container spacing={{ xs: 1, md: 1 }} columns={{ xs: 1, sm: 1, md: 1 }}>
                            <Grid item xs={1} sm={2} md={2}>
                                <Paper variant="outlined" style={{ padding: 15 }}>
                                    <Typography variant='h5'><EditRoundedIcon /> Edit Category</Typography>
                                </Paper>
                            </Grid>
                            <Grid item xs={1} sm={2} md={2}>
                                <Paper variant="outlined" style={{ padding: 15 }}>
                                    <Grid container spacing={{ xs: 1, md: 1 }} columns={{ xs: 1, sm: 1, md: 1 }} className={classes.mainContainer}>
                                        <Grid item xs={1} sm={1} md={1} style={{ display: 'flex' }}>
                                            <TextField
                                                fullWidth
                                                required
                                                id="outlined-required"
                                                label="Category Name"
                                                defaultValue={category.Name_en}
                                                onChange={(event) => {
                                                    setCategory({
                                                        ...category,
                                                        Name_en: event.target.value
                                                    })
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={1} sm={1} md={1} style={{ display: 'flex' }}>
                                            <TextField
                                                fullWidth
                                                required
                                                id="outlined-required"
                                                label="Category Name"
                                                defaultValue={category.Name_ar}
                                                onChange={(event) => {
                                                    setCategory({
                                                        ...category,
                                                        Name_ar: event.target.value
                                                    })
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={1} sm={1} md={1} style={{ display: 'flex', flexWrap: 'wrap' }}>
                                            {iconNames.map((iconName, index) => {
                                                const IconComponent = Icons[iconName];
                                                return (
                                                    <Button
                                                        key={index}
                                                        onClick={() => handleIconSelection(iconName)}
                                                        variant={category.Icon === iconName ? 'contained' : 'outlined'}
                                                    >
                                                        <IconComponent />
                                                    </Button>
                                                );
                                            })}
                                        </Grid>
                                        <Grid item xs={1} sm={1} md={2} className={classes.BtnContainer}>
                                            <Button variant="contained" disabled={editBtnDisabled} onClick={() => handleEditCategory(category)} className={[classes.Btn, classes.submitBtn].join(' ')}>Edit</Button>
                                            <Button variant="outlined" onClick={handleCloseModal} className={[classes.Btn, classes.cancelBtn].join(' ')}>Cancel</Button>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                        </Grid>

                    </Box>
                </Box>
            </Modal>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={snackBar.isOpen}
                autoHideDuration={6000}
                onClose={() => setSnackBar({ ...snackBar, isOpen: false })}
                message={snackBar.message}
            >
                <Alert severity={snackBar.status} sx={{ width: '100%' }}>
                    {snackBar.message}
                </Alert>
            </Snackbar>

        </>
    );
};

export default EditCategory;