import React, { useEffect, useState, Suspense } from 'react';
import { Button, Grid, IconButton, Typography } from '@mui/material';
import Delete from '@mui/icons-material/Delete';
import { useDropzone } from 'react-dropzone';
import ImageRoundedIcon from '@mui/icons-material/ImageRounded';

const ImageUploader = ({ image, onChange, background }) => {
	// Create a root reference

	const [file, setFile] = useState(image ? image : '');
	const bg = background ? background : '#fff';
	const { getRootProps, getInputProps } = useDropzone({
		accept: 'image/*',
		multiple: false,
		maxFiles: 1,
		onDrop: (acceptedFiles) => {
			uploadFile(acceptedFiles[0]);
		},
	});

	useEffect(() => {
		if (file !== '') {
			onChange(file);
		}
	}, [file]);

	useEffect(() => {
		image !== '' ? setFile(image) : setFile('');
	}, [image]);

	const uploadFile = async (image) => {
		const formData = new FormData();
		formData.append('image', image);
		try {
			fetch('/api/upload', {
				method: 'POST',
				body: formData
			})
				.then(response => response.json())
				.then(data => {
					if (data.status == 'success') {
						setFile("/api/" + data.path);
					}
					
				});
		} catch (error) {
			console.error(error);
		}
	};

	const deleteFile = () => {
		const storageRef = file.split('/');
		const imageName = storageRef[storageRef.length - 1];
		// Delete the file
		try {
			fetch('/api/delete/image/' + imageName, {
				method: 'GET',
			})
				.then(response => response.json())
				.then(data => {
					setFile('');
					onChange('')
				});
		} catch (error) {
			console.error(error);
		}
	};

	return (
		<Grid
			container
			spacing={0}
			style={{ minHeight: 100 }}
			alignContent="center"
			justifyContent="center"
			alignItems="center"
		>
			{Boolean(file) && (
				<Grid item xs={12} style={{ background: bg }}>
					<div style={{ position: 'absolute' }}>
						<IconButton varaint="outlined" color="error" onClick={deleteFile}>
							<Delete />
						</IconButton>
					</div>
					<img src={file} style={{ maxWidth: '160px' }} />
				</Grid>
			)}
			{!Boolean(file) && (
				<Grid item xs={12} {...getRootProps({ className: 'dropzone' })}>
					<input {...getInputProps()} />
					<Button size='small' fullWidth color="primary" variant="outlined" style={{ height: '150px', border: '1px solid #d0d0d0', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
						<Typography variant='p'>Select/Drag Image</Typography>
						<Typography variant='p'><ImageRoundedIcon /></Typography>
						<Typography variant='caption' style={{ fontSize: '10px' }}>Formats: JPG, JPEG, PNG, GIF, BMP</Typography>
					</Button>
				</Grid>
			)}
		</Grid>
	);
};

export default ImageUploader;
